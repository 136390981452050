import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { I18Next } from './../../../internationalization';
import { connect } from 'react-redux';
import Authentication from './../../session';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import SearchBarlevel from '../../teachProffTraining/courseManagement/SearchBarlevel';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import '../../dashboard/index';


const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}
class ResourceBranchReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD: false,
            hicWiseUsageReport: [],
            dialogHeader: "",
            showDiolog: false,
            arrToDataTable: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            filterData: null,
            courseData: [],
            isLoading: false,
            courseValue: null

        }



        this.service = new Service();


        // const userAnalyticsBySubjectT = userAnalyticsBySubject.map(item => {
        //     if (item.mp4 > 0 || item.pdf > 0 || item.zip > 0 || item.quiz > 0) {
        //         isGraphDataAvailable = true;
        //     }
        //     return { Videos: item.mp4, Pdf: item.pdf, Ppt: item.zip, Quiz: item.quiz, quizCount: item.quizCount, subjectId: item.subjectId, subjectName: item.subjectName };
        // });
    }





    getCourseType = () => {

        console.log(localStorage.getItem('Bearer'), "localStorage.getItem('Bearer')")
        const url = `${baseUrlAdmin}/course/coursetypes`;

        this.setState({
            isLoading: true
        })
        this.service.post(url, {}, true).then((res) => {


            if (res?.status && res?.res?.status) {
                this.setState({
                    courseData: res.res.data,
                    isLoading: false
                }, () => {

                    if (this.state.courseData?.length > 0) {
                        this.setState({ courseValue: res.res.data[0]._id }, () => {
                            this.getReport();

                        });
                    }
                });
            } else {

                this.setState({
                    isLoading: false
                })
                // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to get the data', life: 3000 });
            }




        }).catch(e => {

            this.setState({
                isLoading: false
            })
            //this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    getReport = () => {
        let { lazyParams, globalSearchUsers, filterData } = this.state;

        this.setState({
            inlineLoading: true
        })

        const payload = {
            "etag": true,
            "selectedKey": this.state.courseValue
        }

        //const url = `${baseUrlAdmin}/course/courseRegion?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearchUsers}`;
        const url = `${baseUrlAdmin}/course/coursebranch`;

        this.service.post(url, payload, true).then(res => {


            if (res && res.status) {
                this.setState({
                    hicWiseUsageReport: res?.res?.data?.branchData,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }

    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.getReport)
    }


    usersClear = () => {
        this.setState({ hicWiseUsageReport: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }

    componentDidMount() {
        this.getCourseType()

    }

    onClickMore = (h) => {
        this.setState({
            showDiolog: true,
            dialogHeader: h
        })
    }

    doPercentUser = (rowData) => {

        console.log(rowData, "rowDatarowDatarowData")
        return rowData?.testPercentage
            ? `${parseFloat(rowData.testPercentage.toFixed(2))}%`
            : "0.00%"; // Default to 0.00% if courseCompletionPercentage is undefined
    };

    onChange = (value) => {
        this.setState((prevState) => {
            return {
                courseValue: value
            };
        });

    }

    handleClearSearch = () => {



        this.setState(
            {
                courseValue: null

            }
        );


    };

    onChangeDropdown = (value) => {
        this.setState(
            {
                courseValue: value

            }, () => {
                this.getReport()
            }
        );

    }
    exportCSV = () => {
        this.dt.exportCSV();
    }

    render() {
        const { defaultLanguage } = this.props;
        const { graphData, stats, inlineLoading, hicWiseUsageReport, filterData } = this.state;
        const langType = defaultLanguage
        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h5 >Branch Wise Report</h5>
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                 
                        
                        <Button onClick={this.exportCSV}>Download</Button>
                   
                </div>
            </div>
        );

        return (
            <div className='ma-relative'>
                <div className='resource-usage-report'>
                    <div className='flex justify-between'>


                        <h3 className="resource-title ma-mt10"><img src="./images/down_arrow_icon.svg" style={{ height: '14px' }} />Branch wise Report</h3>
                        {/* <div style={{ display: 'flex', gap: '5px', }}> */}
                        <div>
                            {/* <SearchBarlevel filterData={this.state.filterData} filterUsersData={this.onSearchClick} usersClear={this.usersClear}  start={0} level={1} /> */}
                            <Dropdown
                                className="ma-mr20 ma-mt10 ma-w200"
                                value={this.state.courseValue}
                                optionLabel="courseName"
                                optionValue="_id"
                                options={this.state.courseData}
                                onChange={(e) => this.onChangeDropdown(e.value)}
                                placeholder={`--Select Course--`}
                            />

                            {/* <Button icon="pi pi-times" className='p-button-rounded ma-button-icon ma-mt10 ma-mr5 ' onClick={this.handleClearSearch} /> */}

                        </div>



                    </div>
                    <hr></hr>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-12' >
                            {
                                <>
                                    <div className='trending-table-rup' style={{ width: "120%" }} >
                                        <table className='trending-table'>
                                            <tr>
                                                <th style={{ color: "#4682b4", font: "normal normal bold 14px/16px Roboto" }}>S.No</th>
                                                <th style={{ color: "#80056f", font: "normal normal bold 14px/16px Roboto" }}>{'Branch'}</th>
                                                <th style={{ color: "#004c99", font: "normal normal bold 14px/16px Roboto" }}>CourseName</th>
                                                <th style={{ color: "#40e0d0", font: "normal normal bold 14px/16px Roboto" }}>Count employee</th>
                                                <th style={{ color: "#800000", font: "normal normal bold 14px/16px Roboto" }}>Course Finishedcount</th>
                                                <th style={{ color: "#3cb371", font: "normal normal bold 14px/16px Roboto" }}>Test completed</th>
                                                <th style={{ color: "#9acd32", font: "normal normal bold 14px/16px Roboto" }}>Test %</th>


                                            </tr>
                                            {hicWiseUsageReport && hicWiseUsageReport?.slice(0, 3).map((hic, index) => {
                                                return (
                                                    <>
                                                        <tr>

                                                            <td>{index + 1}</td>
                                                            <td>{hic?.name}</td>
                                                            <td>
                                                                <p className='course-name-column'>{hic?.courseName}</p>
                                                            </td>
                                                            <td>{hic?.userCount}</td>
                                                            <td>
                                                                {hic?.completedCoursesCount}
                                                            </td>
                                                            <td>{hic?.completedAssessmentsCount}</td>
                                                            <td>{hic?.testPercentage}</td>


                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </table>

                                    </div>

                                    <div className='flex justify-end items-end '>
                                        <span className='ma-mt10 ma-pointer ma-mr15' onClick={() => this.onClickMore("Brach Wise Usage Report")}
                                            style={{ color: "#004cff", font: "normal normal bold italic 13px Roboto" }}>{`${I18Next('more', langType).COMMON_KEY}`}</span>
                                    </div></>
                            }
                        </div>
                    </div>

                </div>



                <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                    //header={<h4>{I18Next('branchWiseUsageReport', langType).COMMON_KEY}</h4>}
                    draggable={false}
                    closeOnEscape={true}
                    blockScroll={false}
                    dismissableMask={false}
                    closable={true}
                    visible={this.state.showDiolog} style={{ maxWidth: '80vw', minWidth: '60vw' }}
                    onHide={() => this.setState({ showDiolog: false })}
                >
                    <div className='card datatable-crud-demo'>
                        <DataTable stripedRows className='asdf'
                            ref={(el) => this.dt = el}
                            //  lazy
                            value={hicWiseUsageReport}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            dataKey="id"
                            paginator
                            // lazy
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Reports"
                            globalFilter={this.state.globalFilter}
                            header={header2}

                        

                        >
                            <Column header='S No' field='_id' body={(rowData, index) => {
                                return (
                                    <p>{index.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header='Branch' field='name' />

                            <Column header='Course Name' field='courseName' />

                            {/* <Column header='Course Name' field='courseName' body={(rowData, index) => {
                                return (
                                    <p className='course-name-column'>{rowData.courseName}</p>)
                            }}
                            /> */}
                            <Column header='Count employee' field='userCount' />


                            <Column header='Course Finishedcount ' field='completedAssessmentsCount' />

                            <Column header='Test completed' field='completedAssessmentsCount' />

                            <Column
                                header="Test %"
                                field="testPercentage"
                            //body={this.doPercentUser}
                            />


                        </DataTable>
                    </div>
                    {
                        this.state.inlineLoadingD && <InLoadingComponent />
                    }
                </Dialog>

                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}




const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(ResourceBranchReport));
