import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toolbar } from 'primereact/toolbar';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';
import { getFormFields, isFieldValid, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
import FileUpload from '../../fileUpload';
import { Dialog } from 'primereact/dialog';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { getBoardsData } from './../../../store/actions';
import Authentication from './../../session';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import _, { flatMap } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { BasicLazyParams, getContentSource } from '../../../utile';
import { Badge } from 'primereact/badge';
import { TestTypes } from '../../examination/constants';
import { MultiSelect } from 'primereact/multiselect';
const items = [
    { label: 'Analytics' },
    { label: 'Proffesional Training' },
    { label: 'Create New Course', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

var createCourseFieldsJson = require('./createCourseFields.json')


const courseTypeOptions = [
    { label: "Mandatory", value: "mandatory" },
    /*     {label : "Choice Based", value : "choiceBased"} */
]

const modeOptions = [
    /*     {label : "Offline", value : "offline"}, */
    { label: "Self Placed", value: "selfPlaced" }
]

const cardImageOptions = [
    { label: "Standard", value: "standard" }
]

const courseImageOptions = [
    { label: "Standard", value: "standard" }
]
const languages = [
    { label: 'English', value: "English" },
    { label: 'Hindi', value: "Hindi" },
    { label: 'Telugu', value: "Telugu" },
    { label: 'Urdhu', value: "Urdhu" },
]

const certificateOptions = [
    { label: "Certificate format 1", value: "theme1" },
    { label: "Certificate format 2", value: "theme2" },
    // {label : "Certificate format 3",value : "theme3"},
]

class CreateCourse extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createCourseFieldsJson);
        this.state = {
            createCourseFields: this.props.editCourse?.courseName ? this.props.editCourse : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            showResource: false,
            boardId: this.props.boards[0]?.boardId,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            chapters: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            filteredContentDetails: [],
            globalFilter: '',
            selectedResource: null,
            showSelectPrerequisite: false,
            selectedPrerequisite: {},
            showSelectPreAssessment: false,
            selectedPreAssessment: {},
            showSelectPostAssessment: false,
            selectedPostAssessment: {},
            testType: "",
            testTypeArr: [],
            testDialog: false,
            lazyParams: BasicLazyParams,
            prerequisiteLabel: "",
            preAssessmentLabel: "",
            postAssessmentLabel: "",
            showSearch: false,
            globalSearch: "",
            credits: "",
            showSelectPreText: false,
            showSelectPostText: false,
            showReads: false,
            readsBoardId: this.props.boards[0]?.boardId,
            readsBoards: [],
            readsClasses: [], readsGroups: [],
            readsClassId: null, readsGroupId: null,
            readsSubjects: [], readsSubjectId: null,
            preReads: {
                boardId: this.props.boards[0]?.boardId,

            },
            postReads: {
                boardId: this.props.boards[0]?.boardId
            }

        }
        this.service = new Service()
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards?.length) {
            this.props.getBoardsData();
        }

        if (this.props.editCourse?.courseName && this.props.boards?.length) {

            this.setState({
                preReads: this.props.editCourse?.preReads,
                postReads: this.props.editCourse?.postReads
            })
            this.formGradesDataForEdit(this.props.editCourse)
        }
        else { this.onChangeBoard(this.props.boards[0].boardId) }
    }

    formGradesDataForEdit = (editCourse) => {

        if (this.props.boards && this.props.boards?.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard = this.props.boards.length > 0 && this.props.boards.find((board) => board.boardId === editCourse.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editCourse.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editCourse.groupId)

                this.setState(
                    {
                        boardId: editCourse.boardId,
                        classId: editCourse.classId,
                        groupId: editCourse.groupId,
                      /*   prerequisiteLabel: this.props.editCourse?.prerequisite?.examName,
                        preAssessmentLabel: this.props.editCourse?.preAssessment.examName, */
                        postAssessmentLabel: this.props.editCourse?.postAssessment?.examName,
                        classes: _classes,
                        readsClasses: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });

            }
        }
    }

    getCurriculumData = (a) => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                    if (a && this.props.editCourse?.courseName) {
                        let c = this.props.editCourse?.curriculum
                        this.setState({ subjectId: c })
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        let createCourseFields = this.state.createCourseFields
        createCourseFields["prerequisite"] = ""
        createCourseFields["preAssessment"] = ""
        createCourseFields["postAssessment"] = ""
        this.setState({
            classes: _classes,
            boardId,
            subjectId: "",
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null,
            createCourseFields: createCourseFields,
            prerequisiteLabel: "",
            preAssessmentLabel: "",
            postAssessmentLabel: ""
        });
    };

    onChangeClass = (classId) => {
        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let createCourseFields = this.state.createCourseFields
            createCourseFields["prerequisite"] = ""
            createCourseFields["preAssessment"] = ""
            createCourseFields["postAssessment"] = ""
            let preReadsData = { boardId: boardId }
            let postReadsData = { boardId: boardId }
            preReadsData.classId = classId
            postReadsData.classId = classId
            preReadsData.groupId = _groups[0].value
            postReadsData.groupId = _groups[0].value
            this.setState({
                groups: _groups,
                classId,
                postReads: preReadsData,
                preReads: postReadsData,
                subjectId: "",
                groupId: _groups[0].value,
                createCourseFields: createCourseFields,
                prerequisiteLabel: "",
                preAssessmentLabel: "",
                postAssessmentLabel: ""
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);
        let createCourseFields = this.state.createCourseFields
        createCourseFields["prerequisite"] = ""
        createCourseFields["preAssessment"] = ""
        createCourseFields["postAssessment"] = ""
        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,
                filteredContentDetails: [],
                createCourseFields: createCourseFields,
                prerequisiteLabel: "",
                preAssessmentLabel: "",
                postAssessmentLabel: ""
            };
        });
    };

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Create New Course</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onBackFromCreate = () => {
        this.props.onBackFromCreate()
    }

    onClickCreateCourse = () => {
        let body = this.state.createCourseFields
        const { preReads, postReads } = this.state

        let startDate = body?.startDate ? new Date(body?.startDate) : null
        let courseStartDate = ""
        if (startDate) {
            let timezoneOffsetForCourseFrom = startDate.getTimezoneOffset();
            startDate.setMinutes(startDate.getMinutes() - timezoneOffsetForCourseFrom);
            courseStartDate = startDate && startDate.toISOString()
        }

        let endDate = body?.endDate ? new Date(body?.endDate) : null
        let courseendDate = ""
        if (endDate) {
            let timezoneOffsetForCourseTo = endDate.getTimezoneOffset();
            endDate.setMinutes(endDate.getMinutes() - timezoneOffsetForCourseTo);
            courseendDate = endDate && endDate.toISOString()
        }




        if (preReads) {
            const { boardId, classId, groupId, subjectId } = preReads
            if (boardId && classId && groupId && subjectId) {
                body.preReads = this.state.preReads
            }
        }
        if (postReads) {
            const { boardId, classId, groupId, subjectId } = postReads
            if (boardId && classId && groupId && subjectId) {
                body.postReads = this.state.postReads
            }
        }


        body.boardId = this.state.boardId
        body.classId = this.state.classId
        body.curriculum = this.state.subjectId
        body.groupId = this.state.groupId
        body.cardImage = "standard"
        body.courseImage = "standard"
        body.startDate = courseStartDate
        body.endDate = courseendDate


        const formStatus = isFormValid(createCourseFieldsJson, this.formFields.formValidations, body);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (!body.postAssessment?.examName) {
            isCompleteFormValid = false
            this.toast.show({ severity: 'error', summary: '', detail: "Please select the assessment tests", life: 3000 });
        }
        if (body.mode === "offline" && (!body.preReads || !body.postReads)) {
            isCompleteFormValid = false
            this.toast.show({ severity: 'error', summary: '', detail: "Please select the Pre-Text and Post-Text", life: 3000 });
        }
        if (isCompleteFormValid) {
            if (this.props.editCourse?.courseName) {
                this.setState({ isLoading: true })

                const url = `${baseUrlAdmin}/course`;
                this.service.put(url, body, true).then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onBackFromCreate("Updated"))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            } else {
                this.setState({ isLoading: true })

                const url = `${baseUrlAdmin}/course`;
                this.service.post(url, body, true).then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onBackFromCreate("created"))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            }
        }
    }

    _onProgress = (filesInfo) => {

    }

    _onWatermarkSuccess = (fileName, data) => {
        this.addAttachment('signature', fileName, data);
        return
    }

    addAttachment = (fieldName, fileName, data) => {
        let createCourseFields = {
            ...this.state.createCourseFields,
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,
            }
        }
        let formValidations = isFieldValid(createCourseFieldsJson.find(fm => fm.FieldName == fieldName), this.state.formValidations, createCourseFields);
        this.setState((prevState) => {
            return { createCourseFields, formValidations }
        });
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    _afterFilesUploaded = (files) => {

    }

    onClickProceed = () => {
        if (this.state.selectedResource) {
            if (this.state.showSelectPrerequisite) {
                let formValidations = this.state.formValidations;
                let examName = this.state.selectedResource?.examName
                let obj = this.state.selectedResource
                obj.status = "pending"
                formValidations.fields["prerequisite"] = { ...formValidations.fields["prerequisite"], isValid: false, errxorMsg: `` }
                this.setState((prevState) => {
                    return {
                        createCourseFields: { ...prevState.createCourseFields, ["prerequisite"]: obj },
                        prerequisiteLabel: examName
                    }
                }, () => this.onHideDialog())
            }
            if (this.state.showSelectPreAssessment) {
                let formValidations = this.state.formValidations;
                let examName = this.state.selectedResource?.examName
                let obj = this.state.selectedResource
                obj.status = "pending"
                formValidations.fields["preAssessment"] = { ...formValidations.fields["preAssessment"], isValid: false, errorMsg: `` }
                this.setState((prevState) => {
                    return {
                        createCourseFields: { ...prevState.createCourseFields, ["preAssessment"]: obj },
                        preAssessmentLabel: examName
                    }
                }, () => this.onHideDialog())
            }
            if (this.state.showSelectPostAssessment) {
                let formValidations = this.state.formValidations;
                let examName = this.state.selectedResource?.examName
                let obj = this.state.selectedResource
                obj.status = "pending"
                formValidations.fields["postAssessment"] = { ...formValidations.fields["postAssessment"], isValid: false, errorMsg: `` }
                this.setState((prevState) => {
                    return {
                        createCourseFields: { ...prevState.createCourseFields, ["postAssessment"]: obj },
                        postAssessmentLabel: examName
                    }
                }, () => this.onHideDialog())
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Please select one test', detail: '', life: 3000 });
        }
    }

    filterHead = () => {
        /*  if(this.state.showSelectPrerequisite){
             return "Self-Assessment"
         }else if(this.state.showSelectPreAssessment){
             return "Pre Assessment"
         }else if(this.state.showSelectPostAssessment){
             return "Post Assessment"
         } */
        if (this.state.showSelectPostAssessment) {
            return "Post Assessment"
        }
    }

    onGlobalSearch = (e) => {
        let assessmentType = ""
        /*  if(this.state.showSelectPrerequisite){
             assessmentType = "prerequisite"
         }
         if(this.state.showSelectPreAssessment){
             assessmentType = "preAssessment"
         } */
        if (this.state.showSelectPostAssessment) {
            assessmentType = "postAssessment"
        }

        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => this.getAssessments(assessmentType));
    }

    onHideDialog = () => {
        /* if(this.state.showSelectPrerequisite){
            this.setState({showSelectPrerequisite : false,testDialog : false,testTypeArr : [],selectedResource : {},globalSearch :"",showSearch: false})
        }else if(this.state.showSelectPreAssessment){
            this.setState({showSelectPreAssessment : false,testDialog : false,testTypeArr : [],selectedResource : {},globalSearch :"",showSearch: false})
        }else if(this.state.showSelectPostAssessment){
            this.setState({showSelectPostAssessment : false,testDialog : false,testTypeArr : [],selectedResource : {},globalSearch :"",showSearch: false})
        } */
        if (this.state.showSelectPostAssessment) {
            this.setState({ showSelectPostAssessment: false, testDialog: false, testTypeArr: [], selectedResource: {}, globalSearch: "", showSearch: false })
        }
    }

    onClickAssessmentselect = (a) => {
        let isHierarchySelected = true
        if (this.state.boardId === "" || this.state.boardId === null || this.state.boardId === undefined) {
            isHierarchySelected = false
        } else if (this.state.classId === "" || this.state.classId === null || this.state.classId === undefined) {
            isHierarchySelected = false
        } else if (this.state.subjectId === "" || this.state.subjectId === null || this.state.subjectId === undefined) {
            isHierarchySelected = false
        }
        if (isHierarchySelected) {
            /*  if(a === "prerequisite"){
                 this.setState({
                     showSelectPrerequisite : true,
                     testDialog : true
                 },() => this.getAssessments('prerequisite'))
             }
             if(a === "preAssessment") {
                 this.setState({
                     showSelectPreAssessment : true,
                     testDialog : true
                 },() => this.getAssessments('preAssessment'))
             } */
            if (a === "postAssessment") {
                this.setState({
                    showSelectPostAssessment: true,
                    testDialog: true
                }, () => this.getAssessments('postAssessment'))
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Please select board,class,curriculum', detail: '', life: 3000 });
        }
    }

    preSelectAssessmentType = (type) => {
        if (this.state.showSelectPrerequisite) {
            let s = this.props.editCourse?.prerequisite
            this.setState({
                selectedResource: s
            })
        }
        if (this.state.showSelectPreAssessment) {
            let s = this.props.editCourse?.preAssessment
            this.setState({
                selectedResource: s
            })
        }
        if (this.state.showSelectPostAssessment) {
            let s = this.props.editCourse?.postAssessment
            this.setState({
                selectedResource: s
            })
        }
    }
    getAssessments = (assessmentType) => {

        this.setState({ isLoading: true })
        const lazyParams = this.state.lazyParams

        const url = `${baseUrlAdmin}/examination/filter?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`;
        this.service.post(url, {
            "examType": "Assessment", "teacherTestType": assessmentType,
            // "boardId" : this.state.boardId,"classId": this.state.classId,
            "subjectId": this.state.subjectId,
            "classId": this.state.classId,

        }, true).then((res) => {
            if (res?.status && res?.res?.success) {
                this.setState({
                    isLoading: false,
                    testTypeArr: res?.res?.data?.exams,
                    totalRecords: res?.res?.data?.totalRecords
                })
                if (this.props.editCourse?.courseName) {
                    this.preSelectAssessmentType(assessmentType)
                }
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.message, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }
    onMultiChange = (value, field) => {

        let formValidations = this.state.formValidations;
        if (value && value.length) {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
        }
        this.setState((prevState) => {
            return {
                createCourseFields: {
                    ...prevState.createCourseFields,
                    [field]: value
                },
                formValidations
            };
        });

    }

    onPage = (event) => {
        let assessmentType = ""
        /* if(this.state.showSelectPrerequisite){
            assessmentType = "prerequisite"
        }
        if(this.state.showSelectPreAssessment){
            assessmentType = "preAssessment"
        } */
        if (this.state.showSelectPostAssessment) {
            assessmentType = "postAssessment"
        }

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, () => this.getAssessments(assessmentType));
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsers);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }//, this.getAssessments
        );
    }

    onClickReads = (type) => {
        const { boardId, classId, groupId, subjectId } = this.state

        if (boardId && classId && groupId && subjectId) {

            if (type === "preText") {
                this.setState({
                    readsBoardId: this.state.preReads.boardId || null,
                    readsClassId: this.state.preReads.classId || null,
                    readsSubjectId: this.state.preReads.subjectId || null,
                    readsGroupId: this.state.preReads.groupId || null,
                    showSelectPreText: true,
                    showSelectPostText: false,
                    showReads: true,
                }, () => this.getReadsCurriculumData("preText"))
            } else if (type === "postText") {

                this.setState({
                    readsBoardId: this.state.postReads.boardId || null,
                    readsClassId: this.state.postReads.classId || null,
                    readsSubjectId: this.state.postReads.subjectId || null,
                    readsGroupId: this.state.postReads.groupId || null,
                    showSelectPostText: true,
                    showSelectPreText: false,
                    showReads: true,
                }, () => this.getReadsCurriculumData("postText"))
            }

        }
        else {
            this.toast.show({ severity: 'error', summary: 'Please select board,class,curriculum', detail: '', life: 3000 });
        }
    }

    onHideReadsDialog = () => {

        if (this.state.showSelectPreText) {
            this.setState({ showSelectPreText: false, showReads: false })
        } else if (this.state.showSelectPostText) {
            this.setState({ showSelectPostText: false, showReads: false })
        }
    }
    onChangeReadsBoard = (boardId) => {

        this.setState({
            readsClasses: [], readsGroups: [],
            readsClassId: null, readsGroupId: null,
            readsSubjects: [], readsSubjectId: null,
            // chapters: [], chapterId: null,
            // topics: [], topicId: null,
            // content: null,
            // filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            readsClasses: _classes,
            readsBoardId: boardId,
        });

    };

    onChangeReadsClass = (classId) => {
        this.setState({
            readsSubjects: [], readsSubjectId: null,
            // groups: [], groupId: null,
            // subjects: [], subjectId: null,
            // chapters: [], chapterId: null,
            // topics: [], topicId: null,
            // content: null,
            // filteredContentDetails: []
        });
        const boardId = this.props.boards[0]?.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                readsGroups: _groups,
                readsClassId: classId,
                readsGroupId: _groups[0].value
            }, () => {
                this.getReadsCurriculumData("class");
            });
        }
    };
    onChangeReadsSubject = (subjectId) => {
        let selectedSubject = this.state.readsSubjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                readsSubjectId: subjectId,
                // chapterId: null,
                // topicId: null,
                //  chapters: selectedSubject[0].chapters,
                // topics: [],
                // content: null,
                // filteredContentDetails: []
            };
        });
    };

    getReadsCurriculumData = (type) => {

        const { getCurriculum } = this.props;
        const { readsBoardId, readsClassId, readsGroupId } = this.state;
        let boardId;
        let classId;
        let groupId;
        if (this.props.editCourse.courseName) {
            boardId = type === "preText" ? this.state.preReads?.boardId : this.state.postReads?.boardId
            classId = type === "preText" ? this.state.preReads?.classId : this.state.postReads?.classId
            groupId = type === "preText" ? this.state.preReads?.groupId : this.state.postReads?.groupId
        }
        else {
            boardId = readsBoardId
            classId = readsClassId
            groupId = readsGroupId
        }
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        readsSubjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };

    onClickReadsProceed = () => {
        const { showSelectPreText, showSelectPostText, readsBoardId, readsClassId, readsSubjectId, readsGroupId } = this.state

        if (showSelectPreText) {

            if (readsBoardId !== null && readsClassId !== null && readsSubjectId !== null) {
                this.setState({
                    preReads: {
                        boardId: readsBoardId,
                        classId: readsClassId,
                        subjectId: readsSubjectId,
                        groupId: readsGroupId,
                    },
                    showReads: false
                })
            }
            else {
                this.toast.show({ severity: 'error', summary: 'Please Select All The Fields*', detail: "", life: 3000 });
            }

        }
        else if (showSelectPostText) {
            if (readsBoardId !== null && readsClassId !== null && readsSubjectId !== null) {
                this.setState({
                    postReads: {
                        boardId: readsBoardId,
                        classId: readsClassId,
                        subjectId: readsSubjectId,
                        groupId: readsGroupId
                    },
                    showReads: false
                })
            }
            else {
                this.toast.show({ severity: 'error', summary: 'Please Select All The Fields*', detail: "", life: 3000 });

            }
        }


    }

    render() {

        const { formValidations, createCourseFields } = this.state
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    {/* <h2 className='ma-table-title'> List</h2> */}
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={(e) => this.onGlobalSearch(e)} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                    </ul>
                </div>
            </div>
        );
        return (

            <>
                <div className='p-grid ma-toolbar'>
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    {/* <p className='back-arrow' onClick={this.onBackFromCreate}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                </div>
                <div className='ma-main'>

                    <div style={{ marginTop: "28px", display: "flex", justifyContent: "space-between" }}>
                        <h2 className='ma-active-item'>{this.props.editCourse.courseName ? "Edit" : "Create New"} Course</h2>
                        <Button style={{ width: "70px", height: "30px", marginTop: "20px" }} type='button' className="back-arrow  p-button-success ma-mlr5" onClick={this.onBackFromCreate}><i style={{ fontSize: "13px" }} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
                    </div>
                    <div className='p-card ma-p20'>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s1'>Course Name<span className='ma-required'>*</span></p>
                                <InputText value={createCourseFields.courseName} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                    onChange={(e) => onTextChange(e.target.value, 'courseName', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['courseName'].isValid && <p className="p-error">{formValidations.fields['courseName'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3 ma-ml30">
                                <p className='ma-label-s1'>Mode<span className='ma-required'>*</span></p>
                                <Dropdown value={createCourseFields.mode} className='ma-w100p md:w-14rem' options={modeOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => { onDropDownChange(e.target.value, 'mode', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['mode'].isValid && <p className="p-error">{formValidations.fields['mode'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3 ma-ml30">
                                <p className='ma-label-s1'>Course Type<span className='ma-required'>*</span></p>
                                <Dropdown value={createCourseFields.type} className='ma-w100p md:w-14rem' options={courseTypeOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => { onTextChange(e.target.value, 'type', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['type'].isValid && <p className="p-error">{formValidations.fields['type'].errorMsg}</p>}
                            </div>
                            {/*   <div className="p-col-12 p-md-2 ma-mr10">
                        <p className='ma-label-s1'>Trainer<span className='ma-required'>*</span></p>
                        <InputText value={createCourseFields.trainer}  className='p-inputtext-style1' 
                            onChange={(e) => {onTextChange(e.target.value, 'trainer', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}
                        />
                        {formValidations && !formValidations.fields['trainer'].isValid && <p className="p-error">{formValidations.fields['trainer'].errorMsg}</p>}
                    </div> */}

                        </div>
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s1'>Start Date</p>
                                <Calendar className='ma-w100p md:w-14rem' dateFormat="dd-mm-yy" value={createCourseFields.startDate ? new Date(createCourseFields.startDate) : null} showIcon
                                    minDate={new Date()}
                                    onChange={(e) => { onTextChange(e.target.value, 'startDate', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['startDate'].isValid && <p className="p-error">{formValidations.fields['startDate'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3 ma-ml30">
                                <p className='ma-label-s1'>End Date</p>
                                <Calendar className='ma-w100p md:w-14rem' dateFormat="dd-mm-yy" value={createCourseFields.endDate ? new Date(createCourseFields.endDate) : null} showIcon minDate={new Date()}
                                    onChange={(e) => { onTextChange(e.target.value, 'endDate', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['endDate'].isValid && <p className="p-error">{formValidations.fields['endDate'].errorMsg}</p>}
                            </div>

                            <div className="p-col-12 p-md-3 ma-ml30">
                                <p className='ma-label-s1'>Select language<span className='ma-required'>*</span></p>
                                <MultiSelect className='ma-w100p md:w-14rem' options={languages} optionLabel="label" optionValue="value"
                                    value={createCourseFields.language}
                                    onChange={(e) => this.onMultiChange(e.value, 'language')}
                                />
                                {/* <Dropdown value={createCourseFields.language} className='ma-w100p md:w-14rem'
                            onChange={(e) =>  { onDropDownChange(e.target.value, 'language', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}
                        /> */}
                                {formValidations && !formValidations.fields['language'].isValid && <p className="p-error">{formValidations.fields['language'].errorMsg}</p>}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s1'>Board<span className='ma-required'>*</span></p>
                                <Dropdown value={this.state.boardId} className='ma-w100p md:w-30rem'

                                    disabled
                                    options={this.props.boards} optionLabel="boardName" optionValue="boardId"
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board" />
                            </div>
                            <div className="p-col-12 p-md-2 ma-ml30">
                                <p className='ma-label-s1'>Class<span className='ma-required'>*</span></p>
                                <Dropdown value={this.state.classId} className='ma-w100p md:w-14rem'
                                    options={this.state.classes} optionLabel="className" optionValue="classId"
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                {formValidations && !formValidations.fields['classId'].isValid && <p className="p-error">{formValidations.fields['classId'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-2 ma-ml30">
                                <p className='ma-label-s1'>Curriculum<span className='ma-required'>*</span></p>
                                <Dropdown value={this.state.subjectId} className='ma-w100p md:w-14rem'
                                    options={this.state.subjects} optionLabel='subjectName' optionValue='subjectId'
                                    onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Curriculum"
                                />
                                {formValidations && !formValidations.fields['curriculum'].isValid && <p className="p-error">{formValidations.fields['curriculum'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-2 ma-ml30">
                                <p className='ma-label-s1'>Credits</p>
                                <InputText value={createCourseFields.credits} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                    onChange={(e) => onTextChange(e.target.value, 'credits', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['credits'].isValid && <p className="p-error">{formValidations.fields['credits'].errorMsg}</p>}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s1'>Course Description</p>
                                <InputTextarea value={createCourseFields.description} name="description" className='ma-w100p p-inputtext-style1 ' rows={5} cols={30} style={{ height: "100px" }}
                                    onChange={(e) => { onTextChange(e.target.value, 'description', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }} />
                                {/* {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>} */}
                            </div>
                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s1'>Course Objective</p>
                                <InputTextarea value={createCourseFields.objective} name="description" className='ma-w100p p-inputtext-style1 ' rows={5} cols={30} style={{ height: "100px" }}
                                    onChange={(e) => { onTextChange(e.target.value, 'objective', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }} />
                                {/* {formValidations && !formValidations.fields['objective'].isValid && <p className="p-error">{formValidations.fields['objective'].errorMsg}</p>} */}
                            </div>
                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s1'>Course outline</p>
                                <InputTextarea value={createCourseFields.outline} name="description" className='ma-w100p p-inputtext-style1 ' rows={5} cols={30} style={{ height: "100px" }}
                                    onChange={(e) => { onTextChange(e.target.value, 'outline', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }} />
                                {/* {formValidations && !formValidations.fields['outline'].isValid && <p className="p-error">{formValidations.fields['outline'].errorMsg}</p>} */}
                            </div>
                        </div>
                        {/*  <div className='p-grid'>
                    <div className="p-col-12 p-md-2 ma-mr10">
                        <p className='ma-label-s1'>Course Strength<span className='ma-required'>*</span></p>
                        <InputText value={createCourseFields.strength} className='p-inputtext-style1'
                            onChange={(e) => {onNumberChange(e.target.value, 'strength', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}/>
                        {formValidations && !formValidations.fields['strength'].isValid && <p className="p-error">{formValidations.fields['strength'].errorMsg}</p>}
                    </div>
                    {createCourseFields.mode == "offline"  &&  <>
                    <div className="p-col-12 p-md-2 ma-mr10">
                        <p className='ma-label-s1'>Venue<span className='ma-required'></span></p>
                        <InputText value={createCourseFields.venue}  className='p-inputtext-style1' 
                            onChange={(e) => {onTextChange(e.target.value, 'venue', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}
                        />
                    </div>
                    <div className="p-col-12 p-md-2 ma-mr10">
                        <p className='ma-label-s1'>Attendance<span className='ma-required'></span></p>
                        <InputText value={createCourseFields.attendance} className='p-inputtext-style1'
                            onChange={(e) => {onNumberChange(e.target.value, 'attendance', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}/>
                    </div>
                    </>}

                </div> */}
                        {createCourseFields.mode === "offline" &&
                            <div className='p-grid p-my-3'>
                                <div className='flex'>

                                    <Button label='Pre-Text' className={this.state.preReads?.subjectId ? "p-buttonReads-success ma-ml10" : "p-text-success ma-ml10"} onClick={() => this.onClickReads("preText")} />
                                    <Button label='Post-Text' className={this.state.postReads?.subjectId ? "p-buttonReads-success ma-ml20" : "p-text-success ma-ml20"} onClick={() => this.onClickReads("postText")} />
                                </div>
                            </div>}

                        <div className='p-grid'>
                            {/*<div className="p-col-12 p-md-6">
                                <p className='ma-label-s1'>Self-Assessment<span className='ma-required'>*</span></p>
                                <div className=''>
                                    <div className='flex'>
                                        <InputText value={this.state.prerequisiteLabel} className='p-inputtext-style1 ma-w50p' readOnly
                                            onChange={(e) => {onTextChange(e.target.value, 'prerequisite', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}/>
                                        <Button label='Select' className= "p-button-success ma-ml20" onClick={() => this.onClickAssessmentselect('prerequisite')}/>
                                    </div>
                                    {formValidations && !formValidations.fields['prerequisite'].isValid && <p className="p-error">{formValidations.fields['prerequisite'].errorMsg}</p>}
                                    <p style={{fontSize : "10px"}}><span className='p-error'>Note : </span>Select From The Test</p>
                                </div>
                            </div>
                             <div className="p-col-12 p-md-6">
                                <p className='ma-label-s1'>Pre Assessment<span className='ma-required'>*</span></p>
                                <div className=''>
                                    <div className='flex'>
                                        <InputText value={this.state.preAssessmentLabel} className='p-inputtext-style1 ma-w50p' readOnly
                                            onChange={(e) => {onTextChange(e.target.value, 'preAssessment', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}/>
                                        <Button label='Select' className= "p-button-success ma-ml20" onClick={() => this.onClickAssessmentselect('preAssessment')}/>
                                    </div>
                                    {formValidations && !formValidations.fields['preAssessment'].isValid && <p className="p-error">{formValidations.fields['preAssessment'].errorMsg}</p>}
                                    <p style={{fontSize : "10px"}}><span className='p-error'>Note : </span>Select From The Test</p>
                                </div>
                            </div> */}
                            <div className="p-col-12 p-md-2">
                                <p className='ma-label-s1'>Course pass percentage(%)<span className='ma-required'>*</span></p>
                                <InputText value={createCourseFields.passMarks} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                    keyfilter={"int"}
                                    onChange={(e) => onTextChange(e.target.value, 'passMarks', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['passMarks'].isValid && <p className="p-error">{formValidations.fields['passMarks'].errorMsg}</p>}
                            </div>
                            <div className="p-col-6">
                                <p className='ma-label-s1'>Post Assessment<span className='ma-required'>*</span></p>
                                <div className=''>
                                    <div className='flex'>
                                        <InputText value={this.state.postAssessmentLabel} className='p-inputtext-style1 ma-w50p' readOnly
                                            onChange={(e) => { onTextChange(e.target.value, 'postAssessment', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations') }} />
                                        <Button label='Select' className="p-button-success ma-ml20" onClick={() => this.onClickAssessmentselect('postAssessment')} />
                                    </div>
                                    {formValidations && !formValidations.fields['postAssessment'].isValid && <p className="p-error">{formValidations.fields['postAssessment'].errorMsg}</p>}
                                    <p style={{ fontSize: "10px" }}><span className='p-error'>Note : </span>Select From The Test</p>
                                </div>
                            </div>

                            {/*  <div className="p-col-12 p-md-4">
                                <div style={{display:"flex",justifyContent:"space-between"}} >
                                    <p className='ma-label-s1'>Upload signature<span className='ma-required'>*</span></p>
                                    <i style={{ float: 'left',marginTop:"10px",fontSize:"13px",color:"#0A9EDC" }} className={`pi pi-info-circle ma-info-i toolId`} data-pr-tooltip="Upload image with maximum size 2MB and in JPG or PNG format." data-pr-position="bottom"  ></i>
                                    <Tooltip className="table-li-tooltip" autoHide={false} target={`.toolId`} />
                                </div>
                                <div className='custom-input2'>
                                    <FileUpload id={"signature"} accept={'images'} title={'Images'} multiple={false} maxFileSize={307200}
                                        onProgress={this._onProgress} onSucess={this._onWatermarkSuccess} onError={this._onFilsUploadError}
                                        afterFilesUploaded={this._afterFilesUploaded}>
                                        <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                    </FileUpload>
                                    <div style={{ height: '35px' }} className='logo-rect-text'>
                                        {(createCourseFields.signature && createCourseFields.signature?.fileName) ? createCourseFields.signature?.fileName : 'No file choosen'}
                                    </div>
                                    {createCourseFields.signature && createCourseFields.signature?.storageLocation && <img src={createCourseFields.signature?.storageLocation} className="" width={150} height={150}/>}
                                </div>
                                {formValidations && !formValidations.fields['signature'].isValid && <p className="p-error">{formValidations.fields['signature'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-6">
                                <p className='ma-label-s1'>Certificate<span className='ma-required'>*</span></p>
                                <Dropdown value={createCourseFields.certificate} className='ma-w50p' placeholder="Select format"
                                    options={certificateOptions} optionLabel='label'optionValue='value'
                                    onChange={(e) => {onDropDownChange(e.target.value, 'certificate', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}
                                />
                                {formValidations && !formValidations.fields['certificate'].isValid && <p className="p-error">{formValidations.fields['certificate'].errorMsg}</p>}
                            </div> */}
                        </div>
                        {/* <div className='p-grid'>
                    <div className="p-col-12 p-md-2">
                        <p className='ma-label-s1'>Card Image<span className='ma-required'>*</span></p>
                        <Dropdown value={createCourseFields.cardImage} className='ma-w100p'
                            options={cardImageOptions} optionLabel='label'optionValue='value'
                            onChange={(e) => {{onTextChange(e.target.value, 'cardImage', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}}
                        />
                        {formValidations && !formValidations.fields['cardImage'].isValid && <p className="p-error">{formValidations.fields['cardImage'].errorMsg}</p>}
                    </div> 
                    <div className="p-col-12 p-md-2">
                        <p className='ma-label-s1'>Course Image<span className='ma-required'>*</span></p>
                        <Dropdown value={createCourseFields.courseImage} className='ma-w100p'
                            options={courseImageOptions} optionLabel='label'optionValue='value'
                            onChange={(e) => {{onTextChange(e.target.value, 'courseImage', this, createCourseFieldsJson, createCourseFields, formValidations, 'createCourseFields', 'formValidations')}}}
                        />
                        {formValidations && !formValidations.fields['courseImage'].isValid && <p className="p-error">{formValidations.fields['courseImage'].errorMsg}</p>}
                    </div>
                </div> */}
                        <div className='p-col-12 p-md-12 flex justify-end'>
                            <Button label={`${this.props.editCourse?.courseName ? "Update" : "Create"} Course`} onClick={this.onClickCreateCourse} />
                        </div>
                    </div>
                </div>

                <Dialog draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true} dismissableMask={false} closable={true} style={{ minHeight: '90vh', maxWidth: '80vw' }}
                    visible={this.state.testDialog} header={() => this.filterHead()}
                    onHide={() => this.onHideDialog()}
                    footer={() => {
                        return (
                            <>
                                <Button label='Proceed' onClick={this.onClickProceed} />
                            </>
                        )
                    }}
                >
                    <div>
                        <div className="card datatable-crud-demo ma-mt30">
                            <DataTable
                                ref={(el) => this.dt = el}
                                id="contentt"
                                value={this.state.testTypeArr}
                                dataKey="_id"
                                header={header}
                                responsiveLayout="scroll"
                                scrollable
                                scrollDirection="both"
                                scrollWidth="flex"
                                paginator
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                // selectionMode = "radiobutton"
                                selection={this.state.selectedResource}
                                onSelectionChange={(e) => this.setState({ selectedResource: e.value })}
                                globalFilter={this.state.globalFilter}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tests"
                            >
                                <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                                <Column filterField="examName" field="examName" header="Resourse Name" />
                                <Column filterField="noOfQuestions" field="noOfQuestions" header="No. of questions" />
                                <Column filterField="totalMarks" field="totalMarks" header="Total Marks" />
                                <Column filterField="totalTime" field="totalTime" header="Total Time" />
                            </DataTable>
                        </div>
                        <div className='flex justify-end ma-mt30'>
                            {/* <Button label='Proceed' onClick={this.onClickProceed} className='ma-ml20'/> */}
                        </div>
                    </div>
                </Dialog>


                <Dialog draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true} dismissableMask={false} closable={true}
                    style={{ minHeight: '40vh', width: '60%' }}
                    visible={this.state.showReads}
                    onHide={() => { this.onHideReadsDialog() }}
                    header={() => {
                        return (
                            <>
                                <h4>{this.state.showSelectPreText ? "Pre-Text" : "Post-Text"}</h4>
                            </>
                        )
                    }}
                    footer={() => {
                        return (
                            <>
                                <Button label='Proceed' onClick={() => this.onClickReadsProceed()} />
                            </>
                        )
                    }}
                >


                    <div className='p-grid' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

                        <div className="p-col-12 p-md-5">
                            <p className='ma-label-s1'>Board<span className='ma-required'>*</span></p>
                            <Dropdown value={this.props.boards[0]?.boardId} className='ma-w100p md:w-30rem'
                                options={this.props.boards} optionLabel="boardName" optionValue="boardId"
                                onChange={(e) => this.onChangeReadsBoard(e.value)}
                                placeholder="Select Board" />
                        </div>
                        <div className="p-col-12 p-md-3 ma-ml30">
                            <p className='ma-label-s1'>Class<span className='ma-required'>*</span></p>

                            <Dropdown value={this.state.readsClassId} className='ma-w100p md:w-14rem'
                                options={this.state.classes} optionLabel="className" optionValue="classId"
                                onChange={(e) => this.onChangeReadsClass(e.value)} placeholder="Select Class" />

                        </div>
                        <div className="p-col-12 p-md-3 ma-ml30">
                            <p className='ma-label-s1'>Curriculum<span className='ma-required'>*</span></p>
                            <Dropdown value={this.state.readsSubjectId} className='ma-w100p md:w-14rem'
                                options={this.state.readsSubjects} optionLabel='subjectName' optionValue='subjectId'
                                onChange={(e) => this.onChangeReadsSubject(e.value)} placeholder="Select Curriculum"
                            />

                        </div>

                    </div>
                </Dialog>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(CreateCourse)))