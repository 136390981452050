import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Navbar from '../../navbar';
import { Toolbar } from 'primereact/toolbar';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../../loadingComponent';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import { TabPanel, TabView } from 'primereact/tabview';
import CreateCourse from './createCourse';
import { Toast } from 'primereact/toast';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import moment from 'moment/moment';
import SvgViewer from '../../customComponents/svgViewer';
import { DeleteIcon, WarningIcon } from '../../svgIcons';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
// import SearchBar from './SearchBar';

import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import { getBoardsData, OnGetCourseData } from './../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Authentication from './../../session';
import ShowAssessmentsData from './showAssessmentsData';
import SearchBar from './SearchBar';
import './styles.scss'

const items = [
    { label: 'Analytics' },
    { label: 'Proffesional Training' },
    { label: 'Course Management', className: 'ma-active-item' }
];
const courseModeOptions = [
    { label: "SELF PLACED", value: "online" },
    /* { label:"OFFLINE", value:"offline"} */
]

const home = { icon: 'pi pi-home' }


export class CourseManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseMode: courseModeOptions[0].value,
            fromDate: "",
            toDate: "",
            courseModeErr: false,
            fromDateErr: false,
            toDateErr: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            //selectedTab: this.props.location?.state?.fromBatch ? (this.props.courseState?.payload?.tab === 1 || this.props.courseState?.payload?.tab === undefined) ? 1 : 2 : 1,
            selectedTab: this.props.location?.state?.fromBatch
                ? (
                    this.props.courseState?.payload?.tab === 1 || this.props.courseState?.payload?.tab === undefined
                        ? 1
                        : this.props.courseState?.payload?.tab === 2
                            ? 2
                            : this.props.courseState?.payload?.tab === 3
                                ? 3
                                : 1  // Default to 1 if no match
                )
                : 1,
            courseManagement: true,
            createCourse: false,
            mandatoryList: [],
            choiceBasedList: [],
            type: "mandatory",
            totalRecords: null,
            editCourse: {},
            warningDialog: warningDailogInit,
            deleteCourseId: null,
            selectedCourses: [],
            selectedCoursesObjects: [],
            showAssignUsers: false,
            filterData: null,
            users: [],
            userstotalRecords: 0,
            showSearch: "",
            globalSearchUsers: "",
            selectedUser: [],
            assessmentData: {},
            showAssessmentsData: false,
            assessmentType: "",
            isOpenBatchButton: false,
            reportData: [],
            columns: []

        }

        this.service = new Service()
    }

    componentDidMount() {

        // if (this.props.location?.state?.fromBatch && this.props.courseState?.payload?.tab) {
        //     const tabType = this.props.courseState?.payload?.tab === 1 ? "mandatory" : "choiceBased"
        //     const courseMode = this.props.courseState?.payload?.courseMode
        //     this.setState({ type: tabType, courseMode: courseMode }, () => this.onClickFetchCourses())


        // }
        if (this.props.location?.state?.fromBatch && this.props.courseState?.payload?.tab) {
            const { tab, courseMode } = this.props.courseState?.payload;

            let tabType = "";

            if (tab === 1) {
                tabType = "mandatory";
            } else if (tab === 2) {
                tabType = "choiceBased";
            } else if (tab === 3) {
                tabType = "report";
            }

            this.setState({ type: tabType, courseMode: courseMode }, () => this.onClickFetchCourses());
        }
        else {
            this.props.OnGetCourseData(({ type: "GET_FETCH_COURSE_STATE", payload: {} }));
            this.onClickFetchCourses()
        }
        if (!this.props?.boards || !this.props?.boards?.length) {
            this.props.getBoardsData();
        }
    }

    generateColumns = (data) => {
        if (data.length === 0 || !data[0].user_info || !data[0].user_info.leafKeys) return [];

        // Extract the unique levels from the leafKeys and create dynamic columns for each level
        const columns = data[0].user_info.leafKeys.map((key) => ({
            field: `level_${key.level}`, // Define field name for the column
            header: key.levelName, // Use `levelName` for column header
            body: (rowData) => {
                // Access the correct leafKey based on level and return the name
                const leafKey = rowData.user_info.leafKeys.find(item => item.level === key.level);
                return leafKey ? leafKey.name : "N/A";
            },
        }));

        this.setState({ columns });
    };
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Course Management</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onClickReset = () => {
        this.setState({
            "fromDate": "",
            "toDate": ""
        })
        this.onClickFetchCourses()
    }

    onClickFetchCourses = () => {


        //     let startDate = new Date(this.state.fromDate);
        //     let timezoneOffset = startDate.getTimezoneOffset();
        //     startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
        //    let courseFromDate = startDate.toISOString()


        //    let EndDate = new Date(this.state.toDate);
        //    let timezoneOffsetEnd = EndDate.getTimezoneOffset();
        //    EndDate.setMinutes(EndDate.getMinutes() - timezoneOffsetEnd);
        //   let courseToDate = EndDate.toISOString()

        let allFieldsSelectd = true
        /*   if (!this.state.fromDate){
             this.setState({fromDateErr : true})
             allFieldsSelectd = false
         }
          if(!this.state.toDate){
              this.setState({toDateErr : true})
             allFieldsSelectd = false
         } */

        if (this.state.type == "mandatory") {
            this.setState({ isLoading: true })
            let lazyParams = this.state.lazyParams
            let x = this.state.type
            const url = `${baseUrlAdmin}/course/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
            this.service.post(url, {
                "type": this.state.type,
                "mode": this.state.courseMode === "online" ? "selfPlaced" : "offline",
                "fromDate": this.state.fromDate || null,
                "toDate": this.state.toDate || null
            }, true).then((res) => {
                if (res?.status && res?.res?.status) {
                    if (this.state.courseMode === "offline") {
                        this.setState({
                            isOpenBatchButton: true
                        })
                    }
                    if (x === "mandatory") {
                        this.setState({
                            mandatoryList: res?.res?.data?.records,
                        })
                    } else {
                        this.setState({
                            choiceBasedList: res?.res?.data?.records,
                        })
                    }
                    this.setState({
                        isLoading: false,
                        selectedTab: x === "mandatory"
                            ? 1
                            : x === "choiceBased"
                                ? 2
                                : x === "report"
                                    ? 3
                                    : 1,

                        courseManagement: true,
                        totalRecords: res?.res?.data?.totalRecords,


                    })
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.message, life: 3000 });
                }
            }).catch(e => {
                this.setState({ isLoading: false });
                // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e?.message, life: 3000 });
            })
        }

        if (this.state.type == "report") {
            this.setState({ isLoading: true })
            let lazyParams = this.state.lazyParams
            let x = this.state.type
            //const url = `${baseUrlAdmin}/course/all/report?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
            const url = `${baseUrlAdmin}/course/all/report`;

            this.service.post(url, {
            }, true).then((res) => {
                if (res?.status && res?.res?.status) {
                    this.setState({
                        reportData: res?.res?.data?.records,
                    })
                    this.setState({
                        isLoading: false,
                        selectedTab: x === "mandatory"
                            ? 1
                            : x === "choiceBased"
                                ? 2
                                : x === "report"
                                    ? 3
                                    : 1,

                        courseManagement: true,
                        totalRecords: res?.res?.data?.totalRecords,


                    })
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.message, life: 3000 });
                }
            }).catch(e => {
                this.setState({ isLoading: false });
                // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e?.message, life: 3000 });
            })
        }
    }

    changeTab = (num, string) => {
        this.setState({
            selectedTab: num,
            lazyParams: BasicLazyParams,
            type: string
        }, () => this.onClickFetchCourses())
    }

    onClickCreateNewCourse = () => {
        this.setState({
            courseManagement: false,
            createCourse: true
        })
    }

    onBackFromCreate = (a) => {
        // this.setState({
        //     courseManagement : true,
        //     createCourse : false,
        //     editCourse : {}
        // })
        if (a) {
            this.setState({
                courseManagement: true,
                createCourse: false,
                editCourse: {},
                selectedCourses: [],
            }, () => this.onClickFetchCourses())
            this.toast.show({ severity: 'success', summary: `Successfully course ${a}`, detail: '', life: 3000 });
        } else {
            this.setState({
                courseManagement: true,
                createCourse: false,
                editCourse: {},
                selectedCourses: [],
                // fromDate : "",
                // fromDateErr : false,
                // toDate : "",
                // toDateErr : false,
                // mandatoryList : [],
                // choiceBasedList : []
            })
        }
    }

    onClickEditCourse = (rowData) => {

        this.setState({
            courseManagement: false,
            createCourse: true,
            editCourse: rowData
        })
    }

    onDeleteCourse = (rowData) => {
        if (rowData?.courseAssignedCount >= 0) {
            this.setState({
                warningDialog: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    message: `"${rowData?.courseName}" has been assigned to ${rowData?.courseAssignedCount} users.Do you want to delete the ${rowData?.courseName} from the course list and unassign it from the users?`

                },
                deleteCourseId: rowData
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Unable to Delete Assigned Courses', life: 3000 });
        }
    }

    onClickDelete = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/course/${this.state.deleteCourseId?._id}`;
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res?.res?.status) {
                this.setState({ isLoading: false }, () => {
                    this.onClickFetchCourses();
                })
                this.toast.show({ severity: 'success', summary: 'Course deleted successfully', detail: res.res.message, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, () => this.onClickFetchCourses());
    }

    onSelectCourse = e => this.setState({ selectedCourses: e.value })

    onChangeSelection = (rowData, e) => {
        if (e.checked) {
            let cId = rowData._id
            /*    rowData.prerequisite.courseId = cId
               rowData.preAssessment.courseId = cId */
            rowData.postAssessment.courseId = cId
            this.setState((prevState) => {
                return {
                    selectedCourses: [...prevState?.selectedCourses, rowData._id],
                    selectedCoursesObjects: [...prevState?.selectedCoursesObjects, rowData]
                }
            })
        } else {
            this.setState((prevState) => {
                let filteredArr = prevState?.selectedCourses.filter(a => a !== rowData._id)
                let filterArrObj = prevState?.selectedCoursesObjects.filter(b => b._id !== rowData._id)
                return {
                    selectedCourses: filteredArr,
                    selectedCoursesObjects: filterArrObj
                }
            })
        }
    }

    onChangeSelectUser = (rowData, e) => {
        if (e.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        } else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }
    }

    onClikAssginUsers = () => {
        if (this.state.selectedCourses.length > 0) {
            this.setState({ showAssignUsers: true })
        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the courses', life: 3000 });
        }
    }

    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.filterUsersData)
    }
    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    onChangeFromDateForCourse = (e) => {
        let startDate = new Date(e.target.value);
        let timezoneOffset = startDate.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - timezoneOffset);


        this.setState({ fromDate: startDate.toISOString(), fromDateErr: false })
    }


    onChangeToDateForCourse = (e) => {
        let endDate = new Date(e.target.value);
        let timezoneOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - timezoneOffset);


        this.setState({ toDate: endDate.toISOString(), toDateErr: false })
    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })
        let { lazyParams, globalSearchUsers, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearchUsers}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);

                console.log(res.res.Items,"users1",users1)
                this.setState({
                    isLoading: false,
                    users: users1,
                    userstotalRecords: res.res.totalRecords
                }, () => {

                    this.generateColumns(users1);


                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
        })
    }

    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onSortUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return { globalSearchUsers: e.target.value, }
        }, () => {
            if (e.target.value.length > 1) {
                this.startGlobalFilter()
            }
        });
    }

    startGlobalFilter = () => {
        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;
            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                // if (!boardId) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                //     return;
                // } else if (boardId && (!classId || !groupId)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     return;
                // }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }
            }
            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }

    onClickAssignExamsToUsers = () => {
        if (this.state.selectedUser.length) {
            let obj = {}
            obj.curriculum = {}
            obj.assignIds = this.state.selectedUser

            let examsArr = []
            this.state.selectedCoursesObjects.map(e => {
                // examsArr.push(e.prerequisite,e.preAssessment,e.postAssessment)
                examsArr.push(e.postAssessment)
            })
            obj.curriculum.boardId = "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7"
            obj.curriculum.classId = "ccb7388c-dfc8-477d-8919-b202db3f422b"
            obj.curriculum.groupId = "1aeababe-3477-427c-8a74-0819caf05d36"
            obj.curriculum.exams = examsArr

            let courseArr = []
            this.state.selectedCoursesObjects.map(e => {
                courseArr.push({ id: e._id, name: e.courseName, assigned: e.type === "mandatory" ? true : false, passMarks: e.passMarks, curriculumInfo: e.curriculumInfo, totalResources: e.totalResources })
            })
            obj.courses = courseArr

            obj.assignTo = "user"
            obj.examType = "Assessment"

            let url = `${baseUrlAdmin}/examination/assigntosection`
            this.setState({ isLoading: true })
            this.service.post(url, obj, true).then(res => {
                if (res && res?.status && res?.res?.success) {
                    this.setState({
                        isLoading: false, showAssignUsers: false, users: [], filterData: null,
                        selectedCourses: [], selectedCoursesObjects: [], selectedUser: [],
                    }, () => this.onClickFetchCourses())
                    this.toast.show({ severity: 'success', summary: '', detail: 'Successfully assigned to users', life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log('e', e)
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
            })

        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the studnets', life: 3000 });
        }
    }
    onClickAssessments = (rowData, assessmentType) => {
        this.setState({
            courseManagement: false,
            showAssessmentsData: true,
            assessmentData: rowData,
            assessmentType: assessmentType
        })
    }

    onBackFromAssessments = () => {
        this.setState({
            courseManagement: true,
            showAssessmentsData: false,
            assessmentData: {},
            assessmentType: '',
            selectedCourses: []
        })
    }

    usersClear = () => {
        this.setState({ users: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }

    getBatchesScreen = (data) => {
        let batchData = { courseMode: this.state.courseMode, tab: this.state.selectedTab }
        this.props.OnGetCourseData(({ type: "GET_FETCH_COURSE_STATE", payload: batchData }));


        this.props.history.push({
            pathname: '/batch-management',
            state: { courseId: data._id, course: data } // Pass any additional data you need
        });
    }

    render() {
        const header2 = (
            <div className="table-header ma-tbl-header ">
                <div className="ma-tbl-left">
                    <h2 >Users List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearchUsers}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearchUsers: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                    <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                        <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                    </Authorizer> 


                    <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </Authorizer>*/}
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                {this.state.columns.map((column) => {
                                    return <li>{column.columnName}</li>
                                })}
                            </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (
            <Navbar>
                {this.state.courseManagement &&
                    <>
                        <div className="p-grid ma-toolbar">
                            <div className="p-col-12 p-md-1 ma-no-p">
                            </div>
                            <div className="p-col-12 p-md-11 ma-no-pm">
                                <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                            </div>
                        </div>
                        <div className='ma-main'>
                            <div className='flex ma-mt20'>
                                <div>
                                    <div>

                                        <Dropdown value={this.state.courseMode} options={courseModeOptions} className='ma-w200' placeholder="Select Course Mode" optionLabel='label' optionValue='value'
                                            onChange={(e) => this.setState({ courseMode: e.target.value, courseModeErr: false, isOpenBatchButton: false })} />
                                        <span className='ma-required-mark '>*</span>
                                    </div>
                                    {this.state.courseModeErr && <span className='p-error'>Course type required</span>}
                                </div>
                                <div className='ma-mlr5'>
                                    <div>
                                        <Calendar placeholder='From Date' dateFormat='dd-mm-yy' showIcon value={this.state.fromDate}
                                            onChange={(e) => this.onChangeFromDateForCourse(e)} />

                                    </div>
                                    {/* {this.state.fromDateErr && <span className='p-error'>From Date required</span>} */}
                                </div>
                                <div className='ma-mlr10'>
                                    <div>
                                        <Calendar placeholder='To Date' dateFormat='dd-mm-yy' showIcon value={this.state.toDate}
                                            onChange={(e) => this.onChangeToDateForCourse(e)} />

                                    </div>
                                    {/* {this.state.toDateErr && <span className='p-error'>To Date required</span>} */}
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                    <Authorizer permId={PERMISSIONS.COURSE_SEARCH}>
                                        <Button label='Fetch Courses' className="p-button-success" onClick={() => this.onClickFetchCourses()} />

                                    </Authorizer>

                                    <Button label='Reset' className="p-button-success ma-mlr5" onClick={() => this.onClickReset()} />
                                </div>
                            </div>
                            <div className='ma-mt30'>
                                <div className='flex-between'>
                                    <div>
                                        <span onClick={() => { this.changeTab(1, 'mandatory') }} className={this.state.selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Mandatory Courses</span>
                                        {/* <span onClick={() => { this.changeTab(2, 'choiceBased') }} className={this.state.selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Choice Based Courses</span> */}
                                        <span onClick={() => { this.changeTab(3, 'report') }} className={this.state.selectedTab == 3 ? 'qb-active-tab' : 'qb-inactive-tab'}>Courses Report</span>

                                    </div>
                                    <Authorizer permId={PERMISSIONS.COURSE_ADD}>
                                        {this.state.type !== "report" && (<Button onClick={() => this.onClickCreateNewCourse()} label='Create new course' icon="pi pi-plus" className="p-button-success" />)}
                                    </Authorizer>

                                </div>
                                {this.state.selectedTab === 1 &&
                                    <div>
                                        <DataTable ref={(el) => this.dt = el}
                                            lazy className='ma-mt30'
                                            value={this.state.mandatoryList}
                                            dataKey="_id"
                                            paginator
                                            onPage={this.onPage}
                                            first={this.state.lazyParams.first}
                                            last={this.state.totalRecords}
                                            rows={this.state.lazyParams.rows}
                                            totalRecords={this.state.totalRecords}

                                            // selectionMode="checkbox"
                                            // selection={this.state.selectedCourses}
                                            // onSelectionChange={this.onSelectCourse}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} courses"
                                            globalFilter={this.state.globalFilter2}
                                            paginatorLeft={() => {
                                                return <>
                                                    <p className='avail-q'> Selected Courses:  <span>{this.state.selectedCourses && this.state.selectedCourses.length}</span> </p>
                                                </>
                                            }}
                                        >
                                            {/* <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column> */}
                                            <Column headerStyle={{ width: '70px' }} body={(rowData) => {
                                                return (<><Checkbox onChange={e => this.onChangeSelection(rowData, e)}
                                                    checked={this.state.selectedCourses?.includes(rowData._id)}
                                                ></Checkbox>
                                                </>)
                                            }} header="Select"
                                            />
                                            <Column field="courseName" header="Course Name" />
                                            {/* <Column field="trainer" header="Trainer" /> */}
                                            {/* <Column field="mode" header="Mode" /> */}
                                            <Column field="language" header="Language" style={{ width: "15rem" }} body={(rowData) => {
                                                return <>{rowData.language.toString()}</>
                                            }} />
                                            {/* <Column field="strength" header="Strength" /> */}
                                            <Column header="Start Date" body={(rowData) => {
                                                return (<>{rowData.startDate ? <span>{moment.utc(rowData.startDate).format('DD-MM-YYYY')}</span> : <span className=''>NA</span>}</>)
                                            }} />
                                            <Column header="End Date" body={(rowData) => {
                                                return (<>{rowData.startDate ? <span>{moment.utc(rowData.endDate).format('DD-MM-YYYY')}</span> : <span className=''>NA</span>}</>)
                                            }} />
                                            <Column header='Actions' body={(rowData) => {
                                                return (
                                                    <>
                                                        {
                                                            (rowData?.courseAssignedCount === 0) ?
                                                                <Authorizer permId={PERMISSIONS.COURSE_EDIT} >
                                                                    <span onClick={() => this.onClickEditCourse(rowData)}>
                                                                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Edit" tooltipOptions={{ position: 'top' }} />
                                                                    </span>
                                                                </Authorizer>
                                                                :
                                                                <span style={{ filter: "blur(1px)" }}>
                                                                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Edit" tooltipOptions={{ position: 'top' }} />
                                                                </span>
                                                        }
                                                        {/* {
                                    (rowData?.courseAssignedCount === 0) ?
                                     <span data-pr-tooltip="Delete Question" data-pr-position="top" className={`ma-ml10 ma-pointer`} onClick={() => this.onDeleteCourse(rowData)}>
                                        <DeleteIcon height={20} width={20}/>
                                     </span>
                                    :
                                    <span style={{filter:"blur(1px)"}} data-pr-tooltip="Delete Question" data-pr-position="top" className={`ma-ml10 ma-pointer`}>
                                        <DeleteIcon height={20} width={20}/>
                                    </span>} */}
                                                        <Button id={rowData._id} onClick={(event) => this[rowData._id].toggle(event)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary ma-ml10' style={{ height: "28px", width: "28px", borderColor: "black" }} />
                                                        <Menu model={[
                                                            /* {
                                                                label: 'Self-Assessment',
                                                                command: () => { this.onClickAssessments(rowData,"Prerequisite") }
                                                            },
                                                            {
                                                                label: 'Pre Assessment', 
                                                                command: () => { this.onClickAssessments(rowData,"Pre Assessment") }
                                                            }, */
                                                            {
                                                                label: 'Post Assessment',
                                                                command: () => { this.onClickAssessments(rowData, "Post Assessment") }
                                                            }
                                                        ]} className="content-more" popup={true} ref={el => this[rowData._id] = el} />
                                                    </>
                                                )
                                            }} />

                                            <Column header='' body={(rowData) => {
                                                if (this.state.isOpenBatchButton) {
                                                    return (
                                                        <Authorizer permId={PERMISSIONS.COURSE_VIEW_BATCHES}>
                                                            <div style={{ width: "40px" }}>
                                                                <Button label="Batches" id={rowData._id} onClick={() => this.getBatchesScreen(rowData)} style={{ height: "28px", width: "85px", borderColor: "black" }} />
                                                            </div>
                                                        </Authorizer>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <p></p>
                                                    )
                                                }
                                            }} />
                                        </DataTable>
                                    </div>
                                }
                                {this.state.selectedTab === 2 &&
                                    <div>
                                        <DataTable ref={(el) => this.dt = el}
                                            lazy
                                            className='ma-mt30'
                                            value={this.state.choiceBasedList}
                                            dataKey="_id"
                                            paginator
                                            onPage={this.onPage}
                                            first={this.state.lazyParams.first}
                                            last={this.state.totalRecords}
                                            rows={this.state.lazyParams.rows}
                                            totalRecords={this.state.totalRecords}
                                            // selectionMode="checkbox"
                                            // selection={this.state.selectedCourses}
                                            // onSelectionChange={this.onSelectCourse}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} courses"
                                            globalFilter={this.state.globalFilter2}
                                            responsiveLayout="scroll"
                                            paginatorLeft={() => {
                                                return <>
                                                    <p className='avail-q'> Selected Courses:  <span>{this.state.selectedCourses && this.state.selectedCourses.length}</span> </p>
                                                </>
                                            }}
                                        >
                                            {/* <Column selectionMode="multiple" headerStyle={{width: '3em'}}/> */}
                                            <Column headerStyle={{ width: '70px' }} body={(rowData) => {
                                                return (<><Checkbox onChange={e => this.onChangeSelection(rowData, e)}
                                                    checked={this.state.selectedCourses?.includes(rowData._id)}
                                                ></Checkbox>
                                                </>)
                                            }} header="Select"
                                            />
                                            <Column field="courseName" header="Course Name" />
                                            {/* <Column field="trainer" header="Trainer" /> */}
                                            <Column field="mode" header="Mode" />
                                            <Column field="language" header="Language" style={{ width: "15rem" }} body={(rowData) => {
                                                return <>{rowData.language.toString()}</>
                                            }} />
                                            {/* <Column field="strength" header="Strength" /> */}
                                            <Column header="Start Date" body={(rowData) => {
                                                return (<><span>{moment(rowData.startDate).format('DD-MM-YYYY')}</span></>)
                                            }} />
                                            <Column header="End Date" body={(rowData) => {
                                                return (<><span>{moment(rowData.endDate).format('DD-MM-YYYY')}</span></>)
                                            }} />
                                            <Column header='Actions' body={(rowData) => {
                                                return (
                                                    <>
                                                        {
                                                            (rowData?.courseAssignedCount === 0) ?
                                                                <Authorizer permId={PERMISSIONS.COURSE_EDIT} >
                                                                    <span onClick={() => this.onClickEditCourse(rowData)}>
                                                                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Edit" tooltipOptions={{ position: 'top' }} />
                                                                    </span>
                                                                </Authorizer>
                                                                :
                                                                <span>
                                                                    <SvgViewer style={{ filter: "blur(1px)" }} className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Edit" tooltipOptions={{ position: 'top' }} />
                                                                </span>}

                                                        {/* {
                                    (rowData?.courseAssignedCount === 0) ?
                                    <span data-pr-tooltip="Delete Question" data-pr-position="top" className={`ma-ml10`} onClick={() => this.onDeleteCourse(rowData)}>
                                        <DeleteIcon height={20} width={20} />
                                    </span>
                                    :
                                    <span style={{filter:"blur(1px)"}} data-pr-tooltip="Delete Question" data-pr-position="top" className={`ma-ml10`}>
                                        <DeleteIcon height={20} width={20} />
                                    </span>} */}
                                                        <Button id={rowData._id} onClick={(event) => this[rowData._id].toggle(event)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary ma-ml10' style={{ height: "28px", width: "28px", borderColor: "black" }} />
                                                        <Menu model={[
                                                            {
                                                                label: 'Self-Assessment',
                                                                command: () => { this.onClickAssessments(rowData, "Prerequisite") }
                                                            },
                                                            {
                                                                label: 'Pre Assessment',
                                                                command: () => { this.onClickAssessments(rowData, "Pre Assessment") }
                                                            },
                                                            {
                                                                label: 'Post Assessment',
                                                                command: () => { this.onClickAssessments(rowData, "Post Assessment") }
                                                            }
                                                        ]} className="content-more" popup={true} ref={el => this[rowData._id] = el} />
                                                    </>
                                                )
                                            }} />
                                            <Column header='' body={(rowData) => {
                                                if (this.state.isOpenBatchButton) {
                                                    return (
                                                        <div style={{ width: "40px" }}>
                                                            <Button label="Batches" id={rowData._id} onClick={() => this.getBatchesScreen(rowData)} style={{ height: "28px", width: "85px", borderColor: "black" }} />
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <p></p>
                                                    )
                                                }
                                            }} />
                                        </DataTable>
                                    </div>
                                }

                                {this.state.selectedTab === 3 &&
                                    <div>
                                        <DataTable ref={(el) => this.dt = el}
                                            lazy className='ma-mt30'
                                            value={this.state.reportData}
                                            dataKey="_id"
                                            //paginator
                                            onPage={this.onPage}
                                            first={this.state.lazyParams.first}
                                            last={this.state.totalRecords}
                                            rows={this.state.lazyParams.rows}
                                            totalRecords={this.state.totalRecords}

                                            // selectionMode="checkbox"
                                            // selection={this.state.selectedCourses}
                                            // onSelectionChange={this.onSelectCourse}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} courses"
                                            globalFilter={this.state.globalFilter2}
                                        // paginatorLeft={() => {
                                        //     return <>
                                        //         <p className='avail-q'> Selected Courses:  <span>{this.state.selectedCourses && this.state.selectedCourses.length}</span> </p>
                                        //     </>
                                        // }}
                                        >

                                            <Column field="courseName" header="Course Name" />

                                            <Column field="courseCompletionCount" header="Course Completion count" />

                                            <Column field="totalUsers" header="Total Users" />

                                            <Column field="completionPercentage" header="Completion %" />
                                            <Column field="testCount" header="Test count" />

                                            <Column field="avgScore" header="Avg Score " />

                                            <Column field="scorePercentage" header="Score% " />
                                        </DataTable>
                                    </div>
                                }
                                <Authorizer permId={PERMISSIONS.ASSIGN_COURSE} >
                                    {
                                        this.state.type !== "report" && (
                                            <Button label="Assign to users" className="p-button-success ma-mt30" onClick={this.onClikAssginUsers} />
                                        )
                                    }
                                </Authorizer>

                            </div>
                        </div>
                    </>}

                <Dialog header={<></>} draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true}
                    dismissableMask={true} closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, deleteCourseId: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onClickDelete} />
                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, deleteCourseId: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg ' style={{ color: "#000", fontSize: "13px" }}>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog visible={this.state.showAssignUsers} header={"Assign to users"} draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true}
                    dismissableMask={false} closable={true} style={{ maxWidth: '70vw', minWidth: '70vw' }}
                    onHide={() => this.setState({ showAssignUsers: false, users: [], selectedUser: [], filterData: {}, showSearch: false, globalSearchUsers: "",userstotalRecords:0 })}>
                    <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} usersClear={this.usersClear} />
                    <div className="card datatable-crud-demo ma-mt10">
                        <DataTable ref={(el) => this.dt = el}
                            lazy
                            value={this.state.users}
                            dataKey="_id"
                            paginator
                            onPage={this.onPageChangeUsers}
                            // onSort={this.onSortUsers}
                            first={this.state.lazyParams.first}
                            last={this.state.userstotalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.userstotalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                            globalFilter={this.state.globalFilter2}
                            //selection={this.state.selectedUser}
                            //onSelectionChange={this.onSelectUser}
                            paginatorLeft={() => {
                                return <>
                                    <p className='avail-q'> Selected Users:  <span>{this.state.selectedUser && this.state.selectedUser.length}</span> </p>
                                </>
                            }}
                            selectionMode="checkbox"

                            header={header2}>
                            <Column headerStyle={{ width: '6em' }} field='_id' header={'Select'}
                                body={(rowData) => {
                                    return <Checkbox checked={this.state.selectedUser.includes(rowData.user_id)}
                                        onChange={(e) => this.onChangeSelectUser(rowData, e)}
                                    ></Checkbox>
                                }}>
                            </Column>
                            <Column field="username" header="User Name" />
                            <Column field="given_name" header="Given Name" />
                            <Column field="phone_number" header="Phone" />
                            <Column field="email" header="Email ID" />
                            <Column field="custom:board" header="Board" />
                            <Column field="custom:grade" header="Grade" />
                            {this.state.columns.map((col) => (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={col.body}
                                    // style={{
                                    //     width: "150px",
                                    //     whiteSpace: "nowrap",
                                    //     overflow: "hidden"
                                    // }}
                                />
                            ))}

                            {/* <Column field="sectionName" header="Section Name" /> */}
                            {/* <Column header="Actions" body={(rowData) => {
                            return (<>
                                <Button label="Assign Assessment" onClick={() => this.assignSubjects(false, [rowData.sectionId])} />
                            </>
                            );
                        }} ></Column> */}
                        </DataTable>
                        <Button label="Assign Course" className='p-button-success ma-mt20' onClick={this.onClickAssignExamsToUsers} />
                    </div>
                </Dialog>
                {this.state.createCourse && <CreateCourse editCourse={this.state.editCourse} onBackFromCreate={this.onBackFromCreate} boards={this.props.boards} />}

                {this.state.showAssessmentsData &&

                    <ShowAssessmentsData data={this.state.assessmentData} assessmentType={this.state.assessmentType} onBackFromAssessments={this.onBackFromAssessments} />}


                {this.state.isLoading && <><LoadingComponent /></>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    courseState: state.courseManageData?.courseState
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});
export default connect(mapStateToProps, {
    getBoardsData, OnGetCourseData
})(Authentication(withRouter(CourseManagement))) 