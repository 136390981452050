import React, { Component } from "react";
import Navbar from "../../navbar";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../loadingComponent";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from "../../../utile/formHelper";
import { trimObj } from "../../../utile";
import Service from "../../../services";
import { baseUrlAdmin } from "../../../store/apiConstants";
import {
  getBranchesLatest
} from './../../../store/actions';
import { connect } from "react-redux";
import Authentication from './../../session'
import { withRouter } from "react-router-dom";
import { userAssignedBoards } from "../../../store/selectors/userAssignedBoards";
import moment from "moment";


const items = [
  { label: "Organization" },
  { label: "Finance", className: "ma-active-item" },
  { label: "Fee Day Sheet", className: "ma-active-item" },
];

const home = { icon: "pi pi-home" };

const formFields = [
  {
    "Type": "Text",
    "Label": "Financail Year",
    "FieldName": "academicYear",
    "Required": true
  },
  {
    "Type": "Text",
    "Label": "Branch",
    "FieldName": "branch",
    "Required": true
  },
  {
    "Type": "Text",
    "Label": "Date Range",
    "FieldName": "dateRange",
    "Required": true
  },
  {
    "Type": "Text",
    "Label": "Dates Between",
    "FieldName": "datesBetween",
    "Required": false
  },
  {
    "Type": "Text",
    "Label": "Cancelled Reciept Logic",
    "FieldName": "cancelRecLogic",
    "Required": true
  }
]

const DateRange = [
  { value: 1, label: 'Today' },
  { value: 2, label: 'Last 7days' },
  { value: 3, label: 'Last 15days' },
  { value: 4, label: 'Last 30days' },
  { value: 5, label: 'Last 60days' },
  { value: 6, label: 'Last 90days' },
  // { value: 7, label: 'Between days' },
]

const CancelledRecieptLogic = [
  { value: 1, label: 'As per cancelled date' },
  { value: 2, label: 'As per reciept date' },
]

class FeeDaySheet extends Component {
  constructor(props) {
    super(props);
    this.formFields = getFormFields(formFields);
    this.state = {
      daySheet: this.formFields.data,
      formValidations: this.formFields.formValidations,
      isLoading: false,
      academicYears: null,

      datesBetween: [],
      sectionsData: [],
    };

    this.service = new Service()
  }
  componentDidMount() {
    this.getAdmissionMetaData()
    // this.getFeeCollectionsDetails()
    
    if (!this.props.branchData) {
      this.props.getBranchesLatest();
    } else {
      
      let sectionsData = {
        level1: this.props.branchData.filter((a) => a.level == 1)
      }
      this.setState({
        sectionsData: sectionsData.level1
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.boards !== this.props.boards) {
      if (this.props.boards) {
        let boards = [];
        this.props.boards &&
          this.props.boards.map((item, index) => {
            boards.push({ value: item.boardId, label: item.boardName });
          });

        this.setState({
          boards: boards
        });
      }
    }

    if (prevProps.branchData !== this.props.branchData) {

      let sectionsData = {
        level1: this.props.branchData.filter((a) => a.level == 1)
      }
      this.setState({
        sectionsData: sectionsData.level1
      });
    }
   
  }

  getAdmissionMetaData = () => {
    let url = `${baseUrlAdmin}/admissions/meta-data`;

    this.setState({
      isLoading: true
    })
    this.service.get(url, true).then(res => {
      if (res && res.status && res.res.status) {
        this.setState((prevState) => {
          return {
            academicYears: res?.res?.data?.academicYears || [],
            admissionTypes: res?.res?.data?.admissionTypes || [],
            isLoading: false
          };
        });
      }
      else {
        this.setState({
          isLoading: false
        });
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      }
    }).catch(e => {
      this.setState({
        isLoading: false
      });
      this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
      console.log(e);
    })
  }

  getFeeCollectionsDetails = () => {
    let body = this.state.daySheet
    let url = `${baseUrlAdmin}/fee-daysheet/`;

    this.setState({
      isLoading: true
    })
    this.service.post(url, body, true).then(res => {
      if (res && res.status && res.res.status) {
        this.setState((prevState) => {
          return {
            feeDayCollection: res?.res?.data,
            isLoading: false
          };
        });
      }
      else {
        this.setState({
          isLoading: false
        });
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      }
    }).catch(e => {
      this.setState({
        isLoading: false
      });
      this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
      console.log(e);
    })

  }

  onClickSearch = () => {
    const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.daySheet));
    let isCompleteFormValid = true;
    if (!formStatus.formValidations.isFormValid) {
      this.setState({
        formValidations: formStatus.formValidations,
      });
      isCompleteFormValid = false;
    }


    if (isCompleteFormValid) {
      this.getFeeCollectionsDetails()
    }
  }

  leftToolbarTemplate() {
    return (
      <React.Fragment>
        <BreadCrumb model={items} home={home} />
      </React.Fragment>
    );
  }

  rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h2 className="ma-tab-title">Fee Day Sheet</h2>
      </React.Fragment>
    );
  };

  render() {
    const { formValidations, daySheet } = this.state
    return (
      <Navbar>
        <div className="p-grid ma-toolbar">
          <div className="p-col-12 p-md-1 ma-no-p">
            {(this.state.isShowChapters ||
              this.state.isShowTopics ||
              this.state.isShowResouces) && (
                <>
                  <p className="back-arrow" onClick={this.onGoBack}>
                    <span>
                      <i className="pi pi-arrow-left"></i>{" "}
                    </span>{" "}
                    Back
                  </p>
                </>
              )}
          </div>
          <div className="p-col-12 p-md-11 ma-no-pm">
            <Toolbar
              className="ma-toolbard"
              left={this.leftToolbarTemplate}
              right={this.rightToolbarTemplate}
            ></Toolbar>
          </div>
        </div>

        <div className="ma-main">
          <div className="p-grid ma-mtb25 ">
            <div className="p-col-6 p-md-4 p-lg-2">
              <Dropdown
                value={daySheet.academicYear}
                className="ma-w200"
                //options={this.state.boards}
                options={this.state.academicYears}
                optionLabel='academicCode'
                optionValue='academicCode'
                onChange={(e) => { onDropDownChange(e.target.value, 'academicYear', this, formFields, daySheet, formValidations, 'daySheet', 'formValidations') }}
                placeholder="Select Finacial Year"
              />
              {formValidations && !formValidations.fields['academicYear'].isValid && <p className="p-error">{formValidations.fields['academicYear'].errorMsg}</p>}
            </div>
            <div className="p-col-6 p-md-4 p-lg-2">
              <Dropdown
                value={daySheet.branch}
                className="ma-w200"
                //options={this.state.boards}
                options={this.state.sectionsData}
                optionLabel="name"
                optionValue="key"
                onChange={(e) => { onDropDownChange(e.target.value, 'branch', this, formFields, daySheet, formValidations, 'daySheet', 'formValidations') }}
                placeholder="Select Branch"
              />
              {formValidations && !formValidations.fields['branch'].isValid && <p className="p-error">{formValidations.fields['branch'].errorMsg}</p>}
            </div>
            <div className="p-col-6 p-md-4 p-lg-2">
              <Dropdown
                value={daySheet.dateRange}
                className="ma-w200"
                //options={this.state.boards}
                options={DateRange}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => { onDropDownChange(e.target.value, 'dateRange', this, formFields, daySheet, formValidations, 'daySheet', 'formValidations') }}
                placeholder="Select Range"
              />
              {formValidations && !formValidations.fields['dateRange'].isValid && <p className="p-error">{formValidations.fields['dateRange'].errorMsg}</p>}
            </div>
            {daySheet.dateRange === 7 &&
              <div className="p-col-6 p-md-4 p-lg-2 ">
                <Calendar
                  selectionMode="range" readOnlyInput
                  value={daySheet.datesBetween}
                  className=""
                  onChange={(e) => { onDropDownChange(e.target.value, 'datesBetween', this, formFields, daySheet, formValidations, 'daySheet', 'formValidations') }}
                  placeholder="Select Dates Range"
                ></Calendar>
                {formValidations && !formValidations.fields['datesBetween'].isValid && <p className="p-error">{formValidations.fields['datesBetween'].errorMsg}</p>}
              </div>}
            <div className="p-col-6 p-md-4 p-lg-2">
              <Dropdown
                value={daySheet.cancelRecLogic}
                className="ma-w200"
                options={CancelledRecieptLogic}
                // options={this.props.boards}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => { onDropDownChange(e.target.value, 'cancelRecLogic', this, formFields, daySheet, formValidations, 'daySheet', 'formValidations') }}
                placeholder="Select Logic"
              />
              {formValidations && !formValidations.fields['cancelRecLogic'].isValid && <p className="p-error">{formValidations.fields['cancelRecLogic'].errorMsg}</p>}
            </div>
            <div className="p-col-6 p-md-4 p-lg-2">
              <Button label='Search' onClick={this.onClickSearch} />
            </div>
          </div>


        </div>
        <div className="">
          <DataTable
            ref={(el) => (this.dt = el)}
            value={this.state.feeDayCollection}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={this.state.globalFilter}
            header={""}
          >
            <Column field="" header="S.No." body={(rowData, index) => {
              return (
                <>
                  <p>{index.rowIndex + 1}</p>
                </>
              )
            }} />
            <Column field="" header="Enrollment Code" />
            <Column field="" header="Application No." />
            <Column field="studentFirstName" header="Studnet Name" />
            <Column field="className" header="Class" />
            <Column field="section" header="Section" />
            <Column field="fatherName" header="Father Name" />
            <Column field="academicYear" header="Acadamic Year" />
            <Column field="_id" header="Transaction ID" />
            <Column field="" header="Fee Type"
              body={(rowData) => {
                return (<p>{rowData?.reciept?.amountBreakup[0]?.particulars}</p>
                )
              }}
            />
            <Column field="reciept.paymentMethod" header="Payment Mode" />
            <Column field="" header="Transaction Details"
              body={(rowData) => {
                return (
                  <>
                    {rowData?.reciept?.paymentMethod == "cash" && <p></p>
                    }
                    {rowData?.reciept?.paymentMethod == "cheque" && <><p>{rowData?.reciept?.chequeNo}</p>
                      <p>{rowData?.reciept?.bankName}</p>
                      <p>{rowData?.reciept?.bankBranch}</p>
                    </>
                    }
                    {rowData?.reciept?.paymentMethod == "credit/debit" && <><p>{rowData?.reciept?.cardType}</p>
                      <p>{rowData?.reciept?.cardTransactionId}</p>
                      <p>{rowData?.reciept?.cardLast4Digits}</p>
                    </>
                    }
                    {rowData?.reciept?.paymentMethod == "upiPayment" && <><p>{rowData?.reciept?.upiUtrNumber}</p>
                      <p>{rowData?.reciept?.upiTransactionId}</p>
                    </>
                    }
                  </>
                )
              }}
            />
            <Column field="" header="Reciept Type" />
            <Column field="" header="Receipt Date" body={(rowData) => {
              return (
                <>

                  <p>{moment(rowData?.reciept?.recieptDate).format('DD-MM-YYYY')}</p>
                </>
              )
            }} />
            <Column field="" header="Amount" body={(rowData) => {
              return (<p>{rowData?.reciept?.totalAmount}</p>
              )
            }} />
            <Column field="" header="Total" />

          </DataTable>


        </div>

        <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
        {this.state.isLoading && <LoadingComponent />}
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
  isUpdated: state.boardsData.isUpdated,
  boardsData: state.boardsData,
  branches: state.branchDataLatest.branches,
  isBranchLoading: state.branchDataLatest.isLoading,
  branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
  branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
})


export default connect(mapStateToProps, { getBranchesLatest })(Authentication(withRouter(FeeDaySheet)))