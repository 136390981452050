
import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import SvgViewer from '../customComponents/svgViewer';
import LoadingComponent from '../loadingComponent';
import Navbar from '../navbar';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { DeleteIcon, WarningIcon } from '../svgIcons';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import { getBoardsData } from './../../store/actions';
import Authentication from './../session';
import CreateVenue from './createVenue';
import { BreadCrumb } from 'primereact/breadcrumb';



const items = [
    { label: 'Proffesional Training' },
    { label: 'Venue Management', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

export class Index extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            globalSearch: '',
            lazyParams: BasicLazyParams,
            totalRecords: null,
            editVenue: {},
            warningDialog: warningDailogInit,
            filterData: null,
            users: [],
            showSearch: "",
            globalSearchUsers: "",
            venueManagement: true,
            createVenue: false,
            selectDist: "",
            selectBlock: "",
            distId: null,
            mandalId: null,
            districtOptions: [{}],
            mandalOptions: [{}],
            selectedDistrict: "",
            selectedMandal: "",
            deleteVenueId: {},
            venueList:[]

        }

        this.service = new Service()
    }


    fetchDistrictOptions=()=>{
    const url = `${baseUrlAdmin}/venue/districts`;
    this.service.get(url,true).then(res => {
         if (res?.status && res?.res?.data) {
            // console.log(res?.res?.data,"ssssres")
             const ddistrictOptions = res?.res?.data 
            // console.log(ddistrictOptions,"ddd")
            this.setState({districtOptions:ddistrictOptions});
                    } else {
                      console.error('Error fetching District options:', res?.message);
                    }
                  })
                .catch((error) => {
                    console.error('Error fetching District options:', error);
                  });
    }
      

    componentDidMount() {  
    this.fetchDistrictOptions();
    this.fetchMandalOptions();
    this.onClickFetchVenues() 
       
    }

    onChangeDistrict = (e) => {
        const selectedDistrict = e.value;
        this.setState({ selectedDistrict, selectedMandal: null }); // Clear Mandal when District changes
        this.fetchMandalOptions(selectedDistrict);
      }
      
    fetchMandalOptions = (selectedDistrict) => {  
    const url = `${baseUrlAdmin}/venue/mandals`;
        this.service.get(url,true)
          .then((res) => {
            // console.log(res,"mandalresponse")
            if (res?.status && res?.res.data) {
              const Options = res?.res?.data
      
              this.setState({ mandalOptions:Options });
            } else {
              console.error('Error fetching Mandal options:', res?.message);
            }
          })
          .catch((error) => {
            console.error('Error fetching Mandal options:', error);
          });
      }

      onChangeMandal=(e)=>{
        this.setState({selectedMandal:e.value})

      }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Venue Manegement</h2>
               
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    // onClickReset = () => {
    //     this.setState({         
    //         "selectDist":"",
    //         "selectBlock":""
    //     })
    //     this.onClickFetchCourses()
    //     // this.onClickFetchVenues()
    // }


    onClickFetchVenues = () => {
        this.setState({ isLoading: true })
        let lazyParams = this.state.lazyParams
        const url = `${baseUrlAdmin}/venue?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        this.service.post(url, {
            district: this.state.selectedDistrict || "",
            mandal: this.state.selectedMandal || ""
        }, true).then((res) => {
            // console.log(res,"venuesResponse")
            if (res?.status && res?.res?.status) {
                this.setState({
                    venueList:res?.res?.data,
                    isLoading: false,
                    venueManagement: true,
                    totalRecords: res?.res?.totalRecords
                })
            }
            else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.message, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e?.message, life: 3000 });
        })
    }


    onClickCreateNewVenue = () => {
        this.setState({
            venueManagement:false,
            createVenue: true
        })
    }

    onBackFromCreate = (a) => {
        this.setState({
            venueManagement : true,
            createVenue : false,
            editVenue: {}
        })
        if (a) {
            this.setState({
                venueManagement: true,
                createVenue: false,
                editVenue: {},
                selectedCourses: [],
            },
                () => this.onClickFetchVenues ()
            )
            this.toast.show({ severity: 'success', summary: `Successfully Venue ${a}`, detail: '', life: 3000 });
        } else {
            this.setState({
                venueManagement: true,
                createVenue: false,
                 editVenue: {},    
            })
        }
    }

    onClickEditVenue = (rowData) => {
        this.setState({
            venueManagement:false,
            createVenue:true,
            editVenue: rowData
        })
    }

    onDeleteVenue = (rowData) => {
        
        // if (rowData?.courseAssignedCount >= 0) {
            this.setState({
               warningDialog: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    // message: `"${rowData?.venueName}" has been assigned to ${rowData?.courseAssignedCount} users.Do you want to delete the ${rowData?.courseName} from the course list and unassign it from the users?`
                    message: `${rowData?.venueName}" Do you want to delete the ${rowData?.venueName} from venues list ?`
                },
                // deleteCourseId: rowData
                deleteVenueId: rowData
            })
        // } 
        // else {
        //     this.toast.show({ severity: 'error', summary: 'Unable to Delete Assigned Courses', life: 3000 });
        // }
    }

    onClickDelete = () => {
        
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/venue/${this.state.deleteVenueId?._id}`;
        this.service.delete(url, true).then((res) => {
            console.log(res,"deleteResponse")
            if (res && res.status && res?.res?.status) {
                this.setState({ isLoading: false }, () => {
                    this.onClickFetchVenues();
                })
                this.toast.show({ severity: 'success', summary: 'Venue deleted successfully', detail: res.res.message, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, () => this.onClickFetchVenues()
            
        );
    }

    onSelectCourse = e => this.setState({ selectedCourses: e.value })

    
    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.filterUsersData)
    }
    
    usersClear = () => {
        this.setState({ users: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }

    render() {
        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className=''>Venue List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.CURRICULUM_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" 
                            // onClick={(event) => this.menu.toggle(event)}
                                className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" 
                            // onClick={this.exportCSV}
                            ></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                {/* <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} /> */}
            </div>
        );
        return (
            <Navbar>
                {this.state.venueManagement &&
                    <>
                        <div className="p-grid ma-toolbar">
                            <div className="p-col-12 p-md-1 ma-no-p">
                            </div>
                            <div className="p-col-12 p-md-11 ma-no-pm">
                                <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                            </div>
                        </div>
                        <div className='ma-main'>
                            <div style={{marginLeft:"27px"}}  className='flex ma-mt20'>
                                <div className='ma-mlr5'>
                                        <Dropdown
                                            value={this.state.selectedDistrict}
                                            options={this.state.districtOptions}
                                            onChange={(e)=>this.onChangeDistrict(e)}
                                            optionLabel="district"
                                            optionValue="district"
                                            className='ma-w200 md:w-22rem'
                                            placeholder="Select District"
                                        />
                                </div>
                                <div className='ma-mlr5'>
                                        <Dropdown
                                            value={this.state.selectedMandal}
                                            options={this.state.mandalOptions}
                                            onChange={(e) => this.onChangeMandal(e)}
                                            optionLabel="mandal"
                                            optionValue="mandal"
                                            className='ma-w200 md:w-22rem'
                                            placeholder="Select Mandal"
                                        />
                                </div>
                                <Authorizer  permId={PERMISSIONS.VENUE_SEARCH}>
                                <div style={{marginTop:"5px",marginLeft:"10px"}}>
                                    <Button label='Fetch Venues' className="p-button-success" onClick={() => this.onClickFetchVenues()} />
                                </div>
                                </Authorizer>
                            </div>
                            <div className='ma-mt30'>
                                <Authorizer permId={PERMISSIONS.VENUE_ADD} >
                                    <div style={{display:"flex",justifyContent:"end",marginRight:"29px",marginBottom:"-18px"}}>
                                    <Button onClick={() => this.onClickCreateNewVenue()} label='Create venue' icon="pi pi-plus" className="p-button-success" />
                                    </div>
                                </Authorizer>
                                   
                               
                                <div className="card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt = el}
                                        lazy className='ma-mt30'
                                        value={this.state.venueList}
                                        dataKey="_id"
                                        paginator
                                        onPage={this.onPage}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        header={header2}
                                        // selectionMode="checkbox"
                                        // selection={this.state.selectedCourses}
                                        // onSelectionChange={this.onSelectCourse}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} venues"
                                        globalFilter={this.state.globalFilter2}
                                        paginatorLeft={() => {
                                            return <>
                                                <p className='avail-q'> Available Venues:  <span>{this.state.venueList && this.state.totalRecords}</span> </p>
                                            </>
                                        }}
                                        >
                                        <Column  header="S.No" body={(rowData, { rowIndex }) => {
                                        return <> {rowIndex + 1} </>
                                        }} />

                                        <Column field="venueName" header="Name" />
                                        <Column field="district" header="District" />
                                        <Column field="mandal" header="Block Name" />
                                        <Column field="venueId" header="Venue Id" />
                                        <Column field="coordinator" header="Incharge" />
                                        <Column field="mobileNo" header="Contact No" />
                                        <Column field="email" header="Email Id" />
                                        <Column header='Actions' body={(rowData) => {
                                            return (
                                                <>
                                                <Authorizer permId={PERMISSIONS.VENUE_EDIT} >
                                                    <span onClick={() => this.onClickEditVenue(rowData)}>
                                                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Edit" tooltipOptions={{ position: 'top' }} />
                                                    </span>
                                                </Authorizer>
                                                <Authorizer permId={PERMISSIONS.VENUE_DELETE} >
                                                    <span data-pr-tooltip="Delete Question" data-pr-position="top" className={`ma-ml10 ma-pointer`} onClick={() => this.onDeleteVenue(rowData)}>
                                                        <DeleteIcon height={20} width={20} />
                                                    </span>
                                                </Authorizer>
                                                </>
                                            )
                                        }} />
                                    </DataTable>
                                </div>

                            </div>
                        </div>
                    </>}

                <Dialog header={<></>} draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true}
                    dismissableMask={true} closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, deleteVenueId: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onClickDelete} />
                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, deleteVenueId: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg ' style={{ color: "#000", fontSize: "13px" }}>{this.state.warningDialog.message}</p>
                </Dialog>
                {
                this.state.createVenue && <CreateVenue editVenue={this.state.editVenue} onBackFromCreate={this.onBackFromCreate}  />}
                {this.state.isLoading && <><LoadingComponent /></>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
});
export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(Index))) 