import React, { Component } from 'react'
import Navbar from '../navbar'
import { Toolbar } from 'primereact/toolbar'
import { BreadCrumb } from 'primereact/breadcrumb'
import Settings from './settings'
import Rules from './rules'

const items = [
    { label: 'Proffesional Training' },
    { label: 'Course Management', },
    { label: "Settings", className: 'ma-active-item' }


];
const home = { icon: 'pi pi-home' }

export class CourseSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: 0,
        }
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Settings</h2>
            </React.Fragment>
        )
    }

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }
    render() {
        return (
            <div>
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>

                    <div className="settings ma-main">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-4 p-xl-4">
                                <div className="p-grid doubts-tabs">

                                    <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 0 ? 'tab-active' : ''}`}>
                                        <p className={`ma-pointer tab-p  ${this.state.currentTab == 0 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(0) }}>Settings</p>
                                    </div>


                                    <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 1 ? 'tab-active' : ''}`}>
                                        <p className={`ma-pointer tab-p ${this.state.currentTab == 1 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(1) }}>Rules</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.currentTab == 0 && <>
                                <Settings/>    
                            </>
                        }
                         {
                            this.state.currentTab == 1 && <>
                                <Rules/>
                            </>
                        }
                    </div>
                </Navbar>
            </div>

        )
    }
}

export default CourseSettings