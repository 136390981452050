import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _, { flatMap } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { TabPanel, TabView } from 'primereact/tabview';
// import CreateCourse from './createCourse';
import { Toast } from 'primereact/toast';
import moment from 'moment/moment';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
// import SearchBar from './SearchBar';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
// import {getBoardsData} from './../../../store/actions';
import { getBoardsData } from './../../store/actions';
import Authentication from './../session';
//  import ShowAssessmentsData from './showAssessmentsData';
import LoadingComponent from '../loadingComponent';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Navbar from '../navbar';
import ShowAssessmentsData from '../teachProffTraining/courseManagement/showAssessmentsData';
import CreateCourse from '../teachProffTraining/courseManagement/createCourse';
import SvgViewer from '../customComponents/svgViewer';
import { DeleteIcon, WarningIcon } from '../svgIcons';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import SearchBar from '../teachProffTraining/courseManagement/SearchBar';
import './styles.scss'
import { InputSwitch } from 'primereact/inputswitch';

const items = [
    { label: 'Analytics' },
    { label: 'Proffesional Training' },
    { label: 'Trainer View', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


export class ViewTeachers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseType: "",
            fromDate: "",
            toDate: "",
            courseTypeErr: false,
            fromDateErr: false,
            toDateErr: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedTab: 1,
            courseManagement: true,
            createCourse: false,
            mandatoryList: [],
            choiceBasedList: [],
            type: "mandatory",
            totalRecords: null,
            editCourse: {},
            currentPage:0,
            itemsPerPage:5,
            warningDialog: warningDailogInit,
            deleteCourseId: null,
            selectedTeachers: [],
            attendenceDate:new Date(),
            selectedCoursesObjects: [],
            isIssueCertificate:true,
            showAssignUsers: false,
         
            filterData: null,
            users: [],
            userstotalRecords: '',
            showSearch: "",
            globalSearchUsers: "",
            selectedUser: [],
            assessmentData: {},
            totalTeachersRecords:[],
            teachersForIssueCertificate: [],
            showAssessmentsData: false,
            assessmentType: "",
            teacherAttendance: true,
            selectAll: true
        }

        this.service = new Service()
    }

    componentDidMount() {

       

        this.onClickFetchTeachers()
        // if (!this.props?.boards || !this.props?.boards?.length) {
        //     this.props.getBoardsData();
        // }
    }

    onClickFetchTeachers = () => {
        this.setState({isLoading : true})
        let lazyParams = this.state.lazyParams

        const Url = `${baseUrlAdmin}/trainer/teachers/certificate-status`;
        const payload = {
            "courseId": this.props.batchData.courseId,
            "batchId": this.props.batchData?._id
        }
        this.service.post(Url, payload, true).then((data) => {
            if (data && data.res && data.res.data) {

                const formatData = data?.res?.data.map(each => {
                    return { 
                            ...each.user_info, 
                            user_id: each.user_id, 
                            account_id: each.account_id, 
                            batches: each.batches ,
                            totalduration:each?.totalduration,
                            daysPresent:each?.daysPresent,
                            preAssessment:each?.preAssessment,
                            postAssessment:each?.postAssessment,
                            prerequisite:each?.prerequisite,
                            isIssueCertificate:each?.isIssueCertificate 
                        }
                })

         

                this.setState({
                    isLoading: false,
                    totalTeachersRecords:formatData,
                    totalRecords: data?.res?.totalRecords
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Trainer Manegement</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    getTheDataPageWise = ()=>{ 
        const indexOfLastItem = (this.state.currentPage + 1) * (this.state.itemsPerPage);
        const indexOfFirstItem = Math.abs(indexOfLastItem - this.state.itemsPerPage);

  // Get the current items to be displayed on the current page
       const currentItems = this.state.totalTeachersRecords.slice(indexOfFirstItem, indexOfLastItem);
       this.setState({teachersForIssueCertificate:currentItems})
     

    }
   

    onPageChange = (event)=>{
     
         this.setState({
             currentPage:event?.page,
             itemsPerPage:event?.rows
         },() => this.getTheDataPageWise())
 
     }

    

    changeTab = (num, string) => {
        this.setState({
            selectedTab: num,
            lazyParams: BasicLazyParams,
            type: string
        }, () => this.onClickFetchCourses())
    }

    onClickCreateNewCourse = () => {
        this.setState({
            courseManagement: false,
            createCourse: true
        })
    }

   
    onBackViewTeachers = () => {
        this.props.onBackViewTeachers()
    }

  

    onDeleteCourse = (rowData) => {
        if (rowData?.courseAssignedCount >= 0) {
            this.setState({
                warningDialog: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    message: `"${rowData?.courseName}" has been assigned to ${rowData?.courseAssignedCount} users.Do you want to delete the ${rowData?.courseName} from the course list and unassign it from the users?`

                },
                deleteCourseId: rowData
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Unable to Delete Assigned Courses', life: 3000 });
        }
    }

    onClickDelete = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/course/${this.state.deleteCourseId?._id}`;
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res?.res?.status) {
                this.setState({ isLoading: false }, () => {
                    this.onClickFetchCourses();
                })
                this.toast.show({ severity: 'success', summary: 'Course deleted successfully', detail: res.res.message, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, () => this.onClickFetchTeachers());
    }

    onSelectCourse = e => this.setState({ selectedCourses: e.value })


    selectAll = () => {
    }
    onSelectAllChange = (event) => {

        const selectAll = event.checked;
        if (selectAll) {

        }

    }
    onSelection = (event) => {
    }

    

    onChangeSelectUser = (rowData, e) => {
        if (e.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        } else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }
    }

    onClikAssginUsers = () => {
        if (this.state.selectedCourses.length > 0) {
            this.setState({ showAssignUsers: true })
        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the courses', life: 3000 });
        }
    }

    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.filterUsersData)
    }
    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })
        let { lazyParams, globalSearchUsers, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearchUsers}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    userstotalRecords: res.res.totalRecords
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
        })
    }

    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onSortUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return { globalSearchUsers: e.target.value, }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }
        });
    }

    startGlobalFilter = () => {
        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;
            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }
            }
            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }

    onClickAssignExamsToUsers = () => {
        if (this.state.selectedUser.length) {
            let obj = {}
            obj.curriculum = {}
            obj.assignIds = this.state.selectedUser

            let examsArr = []
            this.state.selectedCoursesObjects.map(e => {
                examsArr.push(e.prerequisite, e.preAssessment, e.postAssessment)
            })
            obj.curriculum.boardId = "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7"
            obj.curriculum.classId = "ccb7388c-dfc8-477d-8919-b202db3f422b"
            obj.curriculum.groupId = "1aeababe-3477-427c-8a74-0819caf05d36"
            obj.curriculum.exams = examsArr

            let courseArr = []
            this.state.selectedCoursesObjects.map(e => {
                courseArr.push({ id: e._id, name: e.courseName, assigned: e.type === "mandatory" ? true : false })
            })
            obj.courses = courseArr

            obj.assignTo = "user"
            obj.examType = "Assessment"

            let url = `${baseUrlAdmin}/examination/assigntosection`
            this.setState({ isLoading: true })
            this.service.post(url, obj, true).then(res => {
                if (res && res?.status && res?.res?.success) {
                    this.setState({
                        isLoading: false, showAssignUsers: false, users: [], filterData: null,
                        selectedCourses: [], selectedCoursesObjects: [], selectedUser: [],
                    })
                    this.toast.show({ severity: 'success', summary: '', detail: 'Successfully assigned to users', life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log('e', e)
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
            })

        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the studnets', life: 3000 });
        }
    }
    onClickAssessments = (rowData, assessmentType) => {
        this.setState({
            courseManagement: false,
            showAssessmentsData: true,
            assessmentData: rowData,
            assessmentType: assessmentType
        })
    }


    onClickIssueCertificates = (data,type) => {
        
           let usersINfo;
        const url = `${baseUrlAdmin}/batch/status`;
        // const selectedTeachersUserIds = this.state.selectedTeachers.map(a => a.user_id);
        // const usersINfo = this.state.teachersForIssueCertificate.map(each => {
          
        //         return {
        //             user_id: each.user_id,
        //             account_id: each.account_id,
        //             isIssueCertificate: each?.isIssueCertificate

        //         }
         
     // });
     if(type === "issueCertificate"){
         usersINfo = [{
                    user_id: data.user_id,
                    account_id: data.account_id,
                    isIssueCertificate: data?.isIssueCertificate
                }]
     }
     else if(type === "preAssessment"){
         usersINfo = [{
            user_id: data.user_id,
            account_id: data.account_id,
            preAssessment: data?.preAssessment
        }]
     }
     else if(type === "postAssessment"){
         usersINfo = [{
            user_id: data.user_id,
            account_id: data.account_id,
            postAssessment: data?.postAssessment
        }]
     }

     



        const payload = {
            "courseId": this.props.batchData?.courseId,
            "batchId": this.props.batchData?._id,
            "assignedTeacher": usersINfo
        }
     
        this.service.post(url, payload, true).then((res) => {

            if (res?.status || res?.res?.status) {
                this.setState({
                    isLoading: false,
                });
                this.onClickFetchTeachers()
                this.toast.show({ severity: 'success', summary: "success", detail: res?.res?.messsage, life: 3000 });

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.res?.messsage, life: 3000 });
            }

        }).catch(e => {
            this.setState({ isLoading: false });
            //this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e?.message, life: 3000 });
        })
   
    
    }
    
    onChangeIssueCertificates = (e,rowData)=>{



        let data = { ...rowData }
        if (e.value == true) {
            data.isIssueCertificate = true
        } else {
            data.isIssueCertificate = false
        }

            this.onClickIssueCertificates(data,"issueCertificate")
      

        // let updatedTeachers = this.state.teachersForIssueCertificate?.map(teacher=>{
        //     if(teacher?.user_id === data?.user_id){
        //         return data
        //     }
        //     else{
        //         return teacher
        //     }
        // });
            
// this.setState({teachersForIssueCertificate:updatedTeachers})
        
    }
    onChangePreAssessment = (e,rowData)=>{

        let data = { ...rowData }
        if (e.value == true) {
            data.preAssessment = true
        } else {
            data.preAssessment = false
        }

            this.onClickIssueCertificates(data,"preAssessment")

    }
    onChangePostAssessment = (e,rowData)=>{

        let data = { ...rowData }
        if (e.value == true) {
            data.postAssessment = true
        } else {
            data.postAssessment = false
        }

            this.onClickIssueCertificates(data,"postAssessment")
        
    }
    onBackFromAssessments = () => {
        this.setState({
            courseManagement: true,
            showAssessmentsData: false,
            assessmentData: {},
            assessmentType: '',
            selectedCourses: []
        })
    }

    usersClear = () => {
        this.setState({ users: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }
    isSelected = (rowData, selectedItems, uniqField) => {
        
        return selectedItems.find(a => a[uniqField] === rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts

        })





    }

    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    

    
   


    render() {
        console.log(this.props.batchData,"dfsgs")
        const header1 = (
            <div className="table-header ma-tbl-header  ">
                <div className="ma-tbl-left">
                    <h2 >Teachers List For <span style={{color:"green",fontWeight:"bold"}}>{(this.props.batchData?.batchName.charAt(0).toUpperCase()+ this.props.batchData?.batchName.slice(1))}</span></h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearchUsers}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    {/* <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearchUsers: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul> */}
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                {this.state.columns.map((column) => {
                                    return <li>{column.columnName}</li>
                                })}
                            </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        return (
            <>
                <div className='p-grid ma-toolbar'>
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div> 
                </div>
                    {/* <p className='back-arrow' onClick={this.onBackViewTeachers}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                   
               
                <div className='ma-main'>
                <div style={{marginBottom:"-20px",display:"flex",justifyContent:"end"}}>
                <Button type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBackViewTeachers}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
               
                </div> 
                    <div className='ma-mt30 '>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.totalTeachersRecords}
                            dataKey="_id"
                        
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Boards"
                            globalFilter={this.state.globalFilter2}
                            header={header1}
                            paginatorLeft={() => {
                                return <>
                                    <div className="flex selected-users">
                                        {/* <div className='avail-q ma-mr20'>Selected Users:  <span>{this.state.selectedCourses && this.state.selectedCourses.length}</span> </div> */}
                                        <div className='avail-q '>Available  Teachers:  <span>{this.state.totalRecords && this.state.totalRecords}</span> </div>
                                    </div>
                                </>
                            }}

                            // paginatorRight={() => {
                            //     return <>
                            //         <Button className="p-button-success" onClick={this.onClickIssueCertificates}>Issue certificates</Button>
                            //     </>
                            // }}
                        >
                            <Column headerStyle={{width:"80px"}} field="slNo" header="Sr.No" body={(rowData, rowIndex) => {
                                        return <span style={{textAlign:"center"}}>
                                            {(this.state.currentPage * this.state.itemsPerPage) + rowIndex.rowIndex + 1}
                                        </span>
                            }} />
                            <Column headerStyle={{}} field="username" header="User Name " />
                            <Column headerStyle={{}} field="given_name" header="Given Name " />
                            {/* <Column headerStyle={{}} field="username" header="Last Name " /> */}
                            <Column headerStyle={{textAlign:"center",width:"150px"}} field="email" header="Email Id" body={(rowData) => {
                                    return <>
                                       <p style={{width:"120px",textAlign:"center"}}> {rowData?.email}</p>
                                    </>
                                }} />
                            <Column headerStyle={{textAlign:"center"}} field="phone_number" header="Phone" body={(rowData) => {
                                    return <>
                                       <p style={{width:"120px",textAlign:"center"}}> {rowData?.phone_number}</p>
                                    </>
                                }} />
                            <Column headerStyle={{textAlign:"center"}} field="district" header="District" body={(rowData) => {
                                    return <>
                                       <p style={{width:"120px",marginLeft:"-10px",textAlign:"center"}}> {rowData?.distname}</p>
                                    </>
                                }} /> 
                            <Column headerStyle={{width:"120px",textAlign:"center"}} field="prerequisite" header="Self-Assessment" body={(rowData) => {
                                    return <>
                                           <p style={{width:"120px",marginLeft:"-10px",textAlign:"center"}}> {rowData?.prerequisite}</p>
                                    </>
                                }} /> 
                            <Column headerStyle={{width:"120px",textAlign:"center"}} field="preAssessment" header="PreAssessment" body={(rowData) => {
                                    return (
                                      <Authorizer permId={PERMISSIONS.TRAINER_PREASSESSMENT} >
                                        <InputSwitch
                                            checked={rowData?.preAssessment}
                                            tooltip='PreAssessment'
                                            style={{marginLeft:"30px"}}
                                            onChange={(e) => {
                                                this.onChangePreAssessment(e, rowData)
                                            }}
                                        />
                                       {/* <p style={{width:"120px",marginLeft:"-10px",textAlign:"center"}}> {rowData?.preAssessment}</p> */}
                                       </Authorizer>
                                       )
                                }} /> 
                            <Column headerStyle={{width:"120px",textAlign:"center"}} field="postAssessment" header="PostAssessment" body={(rowData) => {
                                    return (
                                        <Authorizer permId={PERMISSIONS.TRAINER_POSTASSESSMENT} >
                                        <InputSwitch
                                             checked={rowData?.postAssessment}
                                            tooltip='PostAssessment'
                                            style={{marginLeft:"30px"}}
                                         
                                            onChange={(e) => {
                                                this.onChangePostAssessment(e, rowData)
                                            }}
                                        />
                                         </Authorizer>   
                                   
                                    )
                                }} />                

                          
                   
                          
                            <Column headerStyle={{textAlign:"center"}} field="view" header="Days Present" body={(rowData) => {
                                    return <p style={{textAlign:"center"}}>{`${rowData?.daysPresent||0}/${rowData?.totalduration}`}</p>
                                }} />

                            <Column headerStyle={{textAlign:"center"}} field="view" header="Issue Certificate" body={(rowData) => {
                                    return    (
                                    <Authorizer permId={PERMISSIONS.TRAINER_ISSUE_CERTIFICATE} >
                                        <InputSwitch
                                            checked={rowData?.isIssueCertificate}
                                            tooltip='Issue a certificate'
                                            style={{marginLeft:"30px"}}
                                            // disabled={!(new Date(this.props.batchData?.fromDate) <= new Date() && new Date() <= new Date(this.props.batchData?.toDate))}
                                            onChange={(e) => {
                                                this.onChangeIssueCertificates(e, rowData)
                                            }}
                                        />
                                    </Authorizer>   
                                    )
                                }} />

                        </DataTable>

                    </div>
                </div>



                {this.state.isLoading && <><LoadingComponent /></>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>


        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});
export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(ViewTeachers))) 