import React from 'react';
import './login.scss';
import logoImg from '../../images/logo.png';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { userSignIn, clearSignInData, getUserData } from './../../store/actions';
import Authentication from './../session';
import { Link, withRouter } from 'react-router-dom';
import LoadingComponent from '../loadingComponent';
import { Carousel } from 'primereact/carousel';
import { I18Next } from '../../internationalization';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';

const INITIAL_STATE = {
    number: '',
    password: '',
    error: null,
    passwordErr: null
};
const carouselData = [
    {
        header: 'Welcome to Professional Development',
        desc: "Apps purpose is to empower you through continuous professional development. The benefits of using the app in their daily work life will be acquiring new age skills, resolving recurring issues and also focus on interactivity through which overall professional success is achieved for self and students in Telangana are benefited with the accomplishments you have and improves the passion towards education"
    },


]

const carouselData1 = [
    {
        img: 'new-assets/carouselImage1.png',
        name: 'image1'

    },


]
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            totalCounts: [],
            pvisible: true,
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 3,
                    numScroll: 3
                },
                {
                    breakpoint: '600px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '480px',
                    numVisible: 1,
                    numScroll: 1
                }
            ]
        };
        this.service = new Service()
    }

    isVaildNumber = (mobile) => {
        if (/^[0-9\b]+$/.test(mobile)) {
            return true;
        } else {
            return false;
        }
    };

    getCounts = (a) => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/organization/stats`;

        this.service.get(url, true).then((data) => {
            if (data && data.res && data.res.data) {
                this.setState({
                    totalCounts: data.res.data,
                    isLoading: false
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
        //  getCurriculum(boardId, classId, groupId);

    };

    componentDidMount() {
        this.getCounts()
        if (this.props.authenticated) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
            if (this.props.authenticated) {
                this.props.getUserData();
                setTimeout(() => {
                    this.props.history.push('/');
                }, 10);
            }
        }
    }

    userSignIn = () => {
        // const number = "+919052565771"  //'shashi2puppy'// `+91${this.state.number}`;
        // const pw ="Lodu@9052565771"       /// 'Dinesh@123'  // this.state.password;
        // const number = `+91${this.state.number}`;
        const pw = this.state.password;
        this.props.userSignIn((this.props.orgCode ? this.props.orgCode : '') + this.state.number, pw);
    };


    productTemplate(product) {
        return (
            <div className="product-item">
                <div className="product-item-content p-grid">
                    <div className="col p-col-4   product-image1 ">
                        <img src={`new-assets/carouseltop.png`} alt={product.name} className="product-image" />
                    </div>
                    <div className='col p-col-7'>
                        <h4 className="p-mb-1 carousel-title ">{product.header}</h4>
                        <h6 className="p-mt-0 p-mb-3 carousel-desc ">{product.desc}</h6>
                    </div>
                </div>
            </div>
        );
    }

    productTemplate1(product) {
        return (
            <div className="product-item ml-3">
                <img src={product.img} alt={product.name} className="carousel-image" />

            </div>
        );
    }

    render() {
        const { userSignIn, number, password, numberErr, passwordErr, totalCounts } = this.state;
        // console.log(this.props.orgCode)
        const showBtn = number === '' || password === '';
        //|| !this.isVaildNumber(number)
        const { logoRectangle } = this.props;
        return (
            <div id="login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflow: 'hidden' }}>
                <div className='m-auto p-col-3 p-lg-2 bodercard md:w-50'>
                    <div className='text-center'>
                        <img src={`/images/celekt.png`}  alt="Logo" style={{ width: '250px' }} /> 
                    </div>
                    <div className='login-header text-center'>

                        <h3 className='login-header-title'>Welcome </h3>
                        {/* <p className='login-header-desc'>Please enter your details</p> */}
                    </div>
                    <div className="col-12 ">
                        <label className='login-label'>Please Enter User Id<span className='ma-required'>*</span></label>
                        <input
                            onChange={(e) => {
                                this.setState({ number: e.target.value });
                            }}
                            value={number}
                            ref="number"
                            type="text"
                            className="input-1"
                            placeholder="username"
                        />
                        <div className="ma-danger">{numberErr ? 'Please enter mobile number' : ' '}</div>
                    </div>
                    <div className="col-12">
                        <label className='login-label'>Password <span className='ma-required'>*</span></label>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <input
                                onChange={(e) => this.setState({ password: e.target.value })}
                                onBlur={() =>
                                    password === ''
                                        ? this.setState({ passwordErr: 'please enter the password' })
                                        : this.setState({ passwordErr: '' })
                                }
                                value={password}
                                ref="password"
                                type={this.state.pvisible ? "password" : "text"}
                                className="input-1"
                                placeholder="Password"
                                style={{ paddingRight: '30px', width: '100%', maxWidth: '400px' }}
                            />
                            <i className={this.state.pvisible ? 'pi pi-eye' : 'pi pi-eye-slash'} style={{
                                alignSelf: 'center',
                                position: 'absolute',
                                right: '10px', // Position the icon to the right of the input
                                cursor: 'pointer', // Change cursor to pointer for better UX
                                top: '50%', // Center vertically
                                transform: 'translateY(-50%)' // Adjust vertical alignment
                            }} onClick={() => this.setState({
                                pvisible: !this.state.pvisible
                            })} />
                        </div>
                        <div className="ma-danger">{passwordErr ? 'Please enter password' : ' '}</div>
                    </div>
                    <div className="text-center text-warning">
                        {this.props.errMsg && this.props.errMsg.message ? this.props.errMsg.message : null}
                    </div>
                    <div style={{ textAlign: 'center', color: 'red' }}>{this.props.errMsg && this.props.errMsg}</div>
                    <div className="mt-3">
                        <Button
                            disabled={showBtn}
                            className='login_btn ma-w100p'
                            label="Sign In"
                            onClick={() => this.userSignIn()} />
                    </div>
                </div>
                {
                    (this.props.isLoading || this.props.loading) && <LoadingComponent />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.login && state.login,
    authenticated: state.auth && state.auth.authenticated,
    loading: state.signInData && state.signInData.loading && state.signInData.loading,
    error: state.signInData && state.signInData.error && state.signInData.error,
    errMsg: state.signInData && state.signInData.errMsg && state.signInData.errMsg.message,
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgCode: state.orgData.orgCode,
    isLoading: state.orgData.isLoading,
    isSuperOrg: state.orgData.isSuper,
});

export default connect(mapStateToProps, { userSignIn, clearSignInData, getUserData })(
    Authentication(withRouter(LoginPage))
);
