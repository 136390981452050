import React, { Component } from 'react'
import Service from '../../../services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import SvgViewer from '../../customComponents/svgViewer';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { InputText } from 'primereact/inputtext';
import {
    getBoardsData,
} from '../../../store/actions';
import { baseUrlAdmin } from '../../../store/apiConstants';
import SingleQuestion from '../../questionBank/common/singleQuestion';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { Dropdown } from 'primereact/dropdown';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../utile/formHelper';
import { QuestionTargets, QuestionTypeKeys } from '../../questionBank/constants';
import { BasicLazyParams, getServerTime } from '../../../utile';
import PreviewQuestion from '../../questionBank/common/previewQuestion';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import LoadingComponent from '../../loadingComponent';
import QuestionInTable from '../../questionBank/common/questionInTable';
import moment from 'moment';
import { TabView, TabPanel } from 'primereact/tabview';
import { Chips } from 'primereact/chips';
import { DeleteIcon, PreviewIcon } from '../../svgIcons';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
const adhocFieldss = require('../examForms/adhoc.json');

let adhocFields = _.cloneDeep(adhocFieldss);




const processEditAdhoc = (editQuiz) => {

    // editUser = _.cloneDeep(editUser);
    // editUser.gradeId = editUser.classId;
    // editUser.phone_number = editUser.phone_number.substring(3);

    // editUser.grade = editUser['custom:grade'];
    // editUser.group = editUser['custom:group'];
    // editUser.board = editUser['custom:board'];
    // editUser.board = editUser['custom:board'];
    return editQuiz;
}


class CreateAdhoc extends Component {
    constructor(props) {
        super(props);


        this.formFields = getFormFields(adhocFields, { questionTarget: 'Adhoc' });
        this.state = {
            exam: this.formFields.data,
            formValidations: this.formFields.formValidations,
            examTemplates: [],
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            boardName: null,
            className: null,
            groupName: null,
            subjectName: null,

            isLoading: false,
            columns: [{ columnName: 'Question Type', isVisible: true, id: 'questionType' },
                // { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
                // { columnName: 'Exam Type', isVisible: false, id: 'examType' },
                // { columnName: 'Source', isVisible: false, id: 'source' },
                // { columnName: 'Status', isVisible: false, id: 'status' },
                // { columnName: 'Question Type', isVisible: false, id: 'questionType' }
            ],
            showSearch: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            questions: [],
            currentStep: 1,
            selectedQIds: [],

            questionIds: [],
            selectedExamQuestions: [],

            //   ...oldState
        }

        this.service = new Service();
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }



    formGradesDataForEdit = (editExam) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editExam.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editExam.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editExam.groupId)

                this.setState(
                    {
                        boardId: editExam.boardId,
                        classId: editExam.classId,
                        groupId: editExam.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,
                        
                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }



    getTemplates = () => {



        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/examtemplate/gettemplatesNames?examType=AdhocMockTest`
        this.service.post(url, {}, true).then((data) => {
            if (data && data.res && data.res.data && data.res.data.length) {
                this.setState({
                    examTemplates: data.res.data,
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }

    formQuestionsFromExam = (exam) => {
        let questions = [];
        exam.subjects.forEach((s) => {
            questions = [...questions, ...s.questions]
        })



        return questions;
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }


        this.getTemplates()




        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });


            this.service.get(`${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/AdhocMockTest`, true).then((data) => {
                if (data && data.res && data.res.Item && data.res.Item.length) {


                        if(!data.res.Item[0].questionTarget) {
                            data.res.Item[0].questionTarget ='Adhoc';
                        }

                    this.setState({
                        exam: data.res.Item[0],
                        selectedExamQuestions: this.formQuestionsFromExam(data.res.Item[0]),
                        isLoading: false,
                    }, () => this.formGradesDataForEdit(data.res.Item[0]));
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });



        }
    }




    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        this.setState((prevState) => ({
            exam: {
                ...prevState.exam,
                boardId,
                boardName: selectedBoard.boardName,
            },
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null
        }));

    };

    onChangeClass = (classId) => {

        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));

            this.setState((prevState) => {
                return {
                    exam: {
                        ...prevState.exam,
                        classId,
                        className: selectedClass.className,
                        groupId: _groups[0].value,
                        groupName: _groups[0].label,
                    },
                    groups: _groups,
                    classId,
                    className: selectedClass.className,
                    groupId: _groups[0].value,
                    groupName: _groups[0].label,
                }
            }, () => {
                this.getCurriculumData();
            }
            );
        }
    }


    // grades selection --- end


    //subjects --- start
    getSelectedCurriculum = (subjects) => {
        let { subjectId, chapterId, topicId } = this.state.exam;

        let selectedSubject = subjects.find((subject) => subject.subjectId == subjectId);
        let selectedChapter = selectedSubject && selectedSubject.chapters && selectedSubject.chapters.find(c => c.chapterId == chapterId);
        let selectedTopic = selectedChapter && selectedChapter.topics && selectedChapter.topics.find(c => c.topicId == topicId);

        return {
            subjects,
            chapters: selectedSubject.chapters,
            topics: (selectedChapter && selectedChapter.topics) ? selectedChapter.topics : [],
            subjectId,
            chapterId,
            topicId,
            subjectName: selectedSubject.subjectName,
            chapterName: selectedChapter ? selectedChapter.chapterName : null,
            topicName: selectedTopic ? selectedTopic.topicName : null,
            content: null,

        };

    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {



                if (data && data.res && data.res.Item) {
                    // let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}

                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,

                        //   ...selected
                    }, () => {





                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (sId, subjectId) => {
        let selectedSubject = this.state.subjects.find((subject) => subject.subjectId == subjectId);
        let exam = this.state.exam;

        exam.subjects.forEach((s) => {
            if (s.id == sId) {
                s.subjectId = subjectId;
                s.subjectName = selectedSubject.subjectName
            }
        })

        this.setState((prevState) => {
            return {
                exam
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,

            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName
            });
    };


    updateDateFileds = (isApplicable) => {
        if (isApplicable == 'closed') {
            adhocFields = adhocFields.map(field => {
                if (field.FieldName == 'fromDateTime' || field.FieldName == 'toDateTime') {
                    field.Required = true;
                }
                return field;
            });

            this.formFields = getFormFields(adhocFields, this.state.org);


            this.setState({
                formValidations: this.formFields.formValidations
            })

        } else {
            adhocFields = adhocFields.map(field => {
                if (field.FieldName == 'fromDateTime' || field.FieldName == 'toDateTime') {
                    field.Required = undefined;
                }
                return field;
            });

            this.formFields = getFormFields(adhocFields, this.state.org);


            this.setState({
                formValidations: this.formFields.formValidations
            })
        }
    }



    isAdditionalValid = (exam) => {
        let { formValidations } = this.state;
        let isValid = true;


        if (exam.testType == 'closed') {
            if (!(moment(exam.fromDateTime).isBefore(exam.toDateTime))) {
                formValidations.fields['fromDateTime'] = {
                    ...formValidations.fields['fromDateTime'],
                    isValid: false,
                    errorMsg: `Exam start date and time should less the end date.`
                }
                isValid = false;
            }
        }


        if (exam.subjects && exam.subjects.length) {
            let isSubjectNotSelected = false;
            let isDuplicateSubject = false;

            let subjectIds = [];
            exam.subjects.forEach(s => {
                if (s.subjectId == '') {
                    isSubjectNotSelected = true
                    isValid = false
                } else {
                    if (subjectIds.includes(s.subjectId)) {

                        isDuplicateSubject = true
                        isValid = false
                    }
                    subjectIds.push(s.subjectId)
                }
            });


            if (isSubjectNotSelected) {
                formValidations.fields['subjects'] = {
                    ...formValidations.fields['subjects'],
                    isValid: false,
                    errorMsg: `Please select all subjects`
                }
            } else {
                formValidations.fields['subjects'] = {
                    ...formValidations.fields['subjects'],
                    isValid: true,
                    errorMsg: ``
                }
            }


            if (!isSubjectNotSelected) {
                if (isDuplicateSubject) {
                    formValidations.fields['subjects'] = {
                        ...formValidations.fields['subjects'],
                        isValid: false,
                        errorMsg: `Duplicate subjects `
                    }
                } else {
                    formValidations.fields['subjects'] = {
                        ...formValidations.fields['subjects'],
                        isValid: true,
                        errorMsg: ``
                    }
                }
            }
        }

        if (exam.testType == 'closed') {
            if (!(moment(exam.fromDateTime).isAfter(getServerTime()))) {
                formValidations.fields['fromDateTime'] = {
                    ...formValidations.fields['fromDateTime'],
                    isValid: false,
                    errorMsg: `Exam start time must greater than current time.`
                }
                isValid = false;
            }


            if (!(moment(exam.fromDateTime).isBefore(exam.toDateTime))) {
                formValidations.fields['toDateTime'] = {
                    ...formValidations.fields['toDateTime'],
                    isValid: false,
                    errorMsg: `Exam end time must greater than start time.`
                }
                isValid = false;
            }

        }



        this.setState({
            formValidations,
            exam
        })

        return isValid;
    }







    onProceed = () => {

        const {
            exam,
            currentStep
        } = this.state;
        if (currentStep == 1) {
            const formStatus = isFormValid(adhocFields, this.formFields.formValidations, exam);
            if (!formStatus.formValidations.isFormValid) {
                this.setState({
                    isSubmitClick: true,
                    formValidations: formStatus.formValidations,
                });
            } else if (!this.isAdditionalValid(exam)) {

            } else {
                this.setState({
                    currentStep: 2
                })
            }
        } else {

            this.validateAndSubmit();
        }
    }



    validateAndSubmit = () => {
        const {
            exam,
            selectedExamQuestions
        } = this.state;


        if (parseInt(exam.totalNoOfQuestions) != selectedExamQuestions.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the required number of questions.', life: 3000 });
            return
        }


        let examToSave = this.processExamData(exam, selectedExamQuestions)

        //   console.log(examToSave);

        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });
            this.service.put(`${baseUrlAdmin}/examination/${exam.examId}/exam-type/AdhocMockTest/update-exam`, examToSave, true).then((data) => {
                if (data && data.res && data.res.success) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.onCreateSuccess(true);
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to create exam record', life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
        } else {
            this.setState({
                isLoading: true
            });
            this.service.post(`${baseUrlAdmin}/examination/add-exam-test`, examToSave, true).then((data) => {
                if (data && data.res && data.res.success) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.onCreateSuccess();
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to create exam record', life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                console.log(e);
            });
        }
    }

    processExamData = (fExam, selectedExamQuestions) => {

        let finalExam = _.cloneDeep(fExam);
        let qNo = 1;
        finalExam.subjects.forEach((s, index) => {
            s.questions = [];
            let subjectQuestions = selectedExamQuestions.filter((q) => q.subjectId == s.subjectId);
            subjectQuestions.sort((a, b) => a.psgQId.localeCompare(b.psgQId));
            subjectQuestions.forEach(question => {
                question.qNo = qNo;
                s.questions.push(question)
                qNo++;
            });
            s.priority = (index + 1);


            s.markingScheme.forEach(schema => {
                schema.negativeMarking = parseInt(schema.negativeMarking)
                schema.optionalQuestions = parseInt(schema.optionalQuestions)
                schema.partialMarking = parseInt(schema.partialMarking)
                schema.positiveMarking = parseInt(schema.positiveMarking)
                schema.totalMarkedQuestions = parseInt(schema.totalMarkedQuestions)
                schema.totalQuestions = parseInt(schema.totalQuestions)
                schema.totalSelectedQuestions = parseInt(schema.totalSelectedQuestions)

            })

        })

        finalExam.gradeId = finalExam.classId;
        finalExam.gradeName = finalExam.className;

        finalExam.totalMarkedQuestions = parseInt(finalExam.totalMarkedQuestions);
        finalExam.totalMarks = parseInt(finalExam.totalMarks);
        finalExam.totalNoOfQuestions = parseInt(finalExam.totalNoOfQuestions);
        finalExam.totalTime = parseInt(finalExam.totalTime);

        if (!finalExam.questionType) {
            finalExam.questionType = finalExam.questionTypes;
        }
        return finalExam
    }






    onAddQuestions = () => {
        let { selectedQuestions, exam } = this.state;
        if (selectedQuestions && selectedQuestions.length) {
            if (exam.noOfQuestions && exam.noOfQuestions != '') {
                if (exam.questions.length < parseInt(exam.noOfQuestions)) {
                    if (selectedQuestions && selectedQuestions.length) {
                        if ((selectedQuestions.length + exam.questions.length) <= parseInt(exam.noOfQuestions)) {
                            exam.questions = [...exam.questions, ...selectedQuestions];
                            exam.questions = [... new Set(exam.questions)]
                            this.setState({
                                exam: exam,
                                selectedQuestions: []
                            });
                        } else {
                            let removeCount = (selectedQuestions.length + exam.questions.length) - parseInt(exam.noOfQuestions);
                            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Number questions are exceeded, Remove ${removeCount} questions`, life: 3000 });
                        }
                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'You have already selected maximum number of question required for the Exam', life: 3000 });
                }
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
            }
        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select questions to add', life: 3000 });
        }
    }



    removeQuestion = (remQ) => {
        let { exam, selectedExamQuestions } = this.state;

        selectedExamQuestions = selectedExamQuestions.filter((q) => q._id != remQ._id);

        exam.subjects.forEach(s => {
            if (s.subjectId == remQ.subjectId) {
                s.markingScheme.forEach((schema) => {
                    if (remQ.questionType == schema.questionType) {

                        schema.totalSelectedQuestions -= 1;
                    } else if (remQ.questionType == QuestionTypeKeys.PQS && schema.questionType == QuestionTypeKeys.PSGS) {
                        schema.totalSelectedQuestions -= 1;
                    } else if (remQ.questionType == QuestionTypeKeys.PQM && schema.questionType == QuestionTypeKeys.PSGM) {
                        schema.totalSelectedQuestions -= 1;
                    }
                })
            }
        })


        this.setState({
            selectedExamQuestions,
            exam
        });
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    onExamStartTImeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {
        let data = JSON.parse(JSON.stringify(formData));

        if ((moment(value).isAfter(getServerTime()))) {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Exam start date and time must be greater than current date and time.`
            }
        }

        if (!(moment(value).isBefore(data.timeFrameEnd))) {
            data.timeFrameEnd = '';
            formValidations.fields['timeFrameEnd'] = {
                ...formValidations.fields['timeFrameEnd'],
                isValid: false,
                errorMsg: `Please select exam end date, start date changed.`
            }
        }

        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    onExamEndTimeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        data[fieldName] = new Date(value).toString();
        if (moment(value).isAfter(data.fromDateTime)) {

            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Exam End date must be greater than start date.`
            }
        }
        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    onTemplateChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        if (value) {
            data[fieldName] = value;
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Exam End date must be greater than start date.`
            }
        }
        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations,
        }, () => this.getExamTemplate(value));
    }

    getExamTemplate = (templateId) => {

        this.setState({
            isLoading: true
        });


        this.service.get(`${baseUrlAdmin}/examtemplate/getTemplateById?templateId=${templateId}`, true).then((data) => {

            if (data && data.res && data.res.data) {
                this.setState((prevState) => {
                    return {
                        examTemplate: data.res.data,
                        exam: { ...prevState.exam, ...data.res.data },
                        isLoading: false
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });




    }


    canAddQuestion = (finalQuestions, subjects, question) => {
        let canAdd = false;

        let isAlreadyAdded = false;
        if (question.question.questionType == QuestionTypeKeys.PSGS || question.question.questionType == QuestionTypeKeys.PSGM) {

            question.question.questions.forEach((subQ) => {
                if (finalQuestions.find(sq => sq.questionId == subQ.questionId)) {
                    isAlreadyAdded = true
                };
            })

        } else {
            if (finalQuestions.find(sq => sq.questionId == question.question.questionId)) {
                isAlreadyAdded = true
            };
        }

        if (isAlreadyAdded) {
            return false
        }


        subjects.forEach(s => {
            if (s.subjectId == question.subjectId) {
                s.markingScheme.forEach((schema) => {
                    if (schema.questionType == question.question.questionType) {
                        if (!schema.totalSelectedQuestions) {
                            schema.totalSelectedQuestions = 0;
                        }

                        if (schema.questionType == QuestionTypeKeys.PSGS || schema.questionType == QuestionTypeKeys.PSGM) {
                            if (schema.totalSelectedQuestions < parseInt(schema.totalQuestions)) {
                                if (schema.totalSelectedQuestions + question.question.questions.length <= parseInt(schema.totalQuestions)) {
                                    schema.totalSelectedQuestions += question.question.questions.length;
                                    canAdd = true;
                                }

                            }
                        } else {
                            if (schema.totalSelectedQuestions < parseInt(schema.totalQuestions)) {
                                schema.totalSelectedQuestions += 1;
                                canAdd = true;
                            }
                        }

                    }
                })
            }
        })
        return canAdd;
    }


    orderQuestions = (questions, qIds) => {
        let qs = [];
        qIds.forEach(qId => {
            let inex = questions.findIndex(q => q.questionId == qId);
            if (inex >= 0) {
                let dd = JSON.parse(JSON.stringify(questions.splice(inex, 1)[0]))
                qs.push(dd);
            }

        });



        return qs.concat(questions);

    }


    onFetchQuestions = (questions, qIds) => {

        if (questions && questions.length) {
            questions = this.orderQuestions(questions, qIds);

        }

        let notFoundQuestions = [];

        
        for (let i = 0; i < qIds.length; i++) {
            let question = questions.find(q => q.questionId == qIds[i]);
            if (!question) {
                notFoundQuestions.push(qIds[i]);
            }

        }

        if (notFoundQuestions.length) {

            this.toast.show({ severity: 'error', summary: 'Questions are not added due to some questions not found.', detail: `Having issue with the following questions${notFoundQuestions.join(',')}.`, life: 20000 });
            return;

        }

        let { exam, selectedExamQuestions } = this.state;
        let subjectIds = exam.subjects.map((q) => q.subjectId);

        let passageQuestions = [];
        let finalQuestions = _.cloneDeep(selectedExamQuestions);
        let examT = _.cloneDeep(exam);


        let duplicateQuestiosn = [];
        if (questions.length) {
            questions.forEach(q => {
                if (q && q.question) {
                    if (q.question.questionType == 'PSGS' || q.question.questionType == 'PSGM') {
                        q.question.questions.forEach(subQ => {
                            if (finalQuestions.find(qa => qa.questionId == subQ.questionId)) {
                                if (!duplicateQuestiosn.includes(q.questionId)) {
                                    duplicateQuestiosn.push(q.questionId)
                                }
                            }
                        })
                    } else {
                        if (finalQuestions.find(qa => qa.questionId == q.questionId)) {
                            if (!duplicateQuestiosn.includes(q.questionId)) {
                                duplicateQuestiosn.push(q.questionId)
                            }

                        }
                    }
                }
            })
        }



        if (duplicateQuestiosn.length) {
            this.toast.show({ severity: 'error', summary: 'Questions are not added due to duplicate questions.', detail: `Having issue with the following questions${duplicateQuestiosn.join(',')}.`, life: 20000 });
            return;
        }










        let questionsNotInSubjects = [];
        let newlyAddedQs = [];
        let notFitQuestions = [];


        //let availableQuestions = [];

        if (questions && questions.length) {
            questions.forEach(q => {
                if (subjectIds.includes(q.subjectId)) {
                    if (q.question.questionType == QuestionTypeKeys.PSGS || q.question.questionType == QuestionTypeKeys.PSGM) {
                        if (this.canAddQuestion(finalQuestions, examT.subjects, q)) {

                            q.question.questions.forEach((subQ) => {
                                subQ.paragraph = q.question.paragraph;
                                subQ.paragraphUrl = q.question.paragraphUrl;
                                subQ.videoLinking = q.question.videoLinking;
                                subQ.psgQId = q.question.questionId;
                                subQ.subjectId = q.subjectId;
                                subQ.subjectName = q.subjectName;
                                subQ.difficulty = q.difficulty;


                                finalQuestions.push(subQ);
                            })
                            newlyAddedQs.push(q.questionId)
                        } else {
                            if (q && q.question) {
                                if (q.question.questionType == 'PSGS' || q.question.questionType == 'PSGM') {
                                    q.question.questions.forEach(subQ => {
                                        if (!finalQuestions.find(qa => qa.questionId == subQ.questionId) && !notFitQuestions.includes(q.questionId)) {
                                            notFitQuestions.push(q.questionId)
                                        }
                                    })
                                } else {
                                    if (!finalQuestions.find(qa => qa.questionId == q.questionId) && !notFitQuestions.includes(q.questionId)) {
                                        notFitQuestions.push(q.questionId)
                                    }
                                }
                            }
                        }
                    } else {
                        if (this.canAddQuestion(finalQuestions, examT.subjects, q)) {

                            q.question.subjectId = q.subjectId;
                            q.question.psgQId = '';
                            finalQuestions.push(q.question);
                            newlyAddedQs.push(q.questionId)
                        } else {

                            if (q && q.question) {
                                if (q.question.questionType == 'PSGS' || q.question.questionType == 'PSGM') {
                                    q.question.questions.forEach(subQ => {
                                        if (!finalQuestions.find(qa => qa.questionId == subQ.questionId) && !notFitQuestions.includes(q.questionId)) {
                                            notFitQuestions.push(q.questionId)
                                        }
                                    })
                                } else {
                                    if (!finalQuestions.find(qa => qa.questionId == q.questionId) && !notFitQuestions.includes(q.questionId)) {
                                        notFitQuestions.push(q.questionId)
                                    }
                                }
                            }




                        }


                        passageQuestions.push(q.question)
                    }
                } else {
                    if (!questionsNotInSubjects.includes(q.questionId)) {
                        questionsNotInSubjects.push(q.questionId)
                    }
                }
            });
        }

        newlyAddedQs.forEach(q => {
            notFitQuestions.splice(notFitQuestions.indexOf(q), 1);
            questionsNotInSubjects.splice(questionsNotInSubjects.indexOf(q), 1);
        })


        if (notFitQuestions.length || questionsNotInSubjects.length) {
            this.toast.show({ severity: 'error', summary: 'Questions are not added due to some questions are not applicable to criteria', detail: `having issue with the following questios. ${notFitQuestions.join(', ')} ,${questionsNotInSubjects.join(', ')}.`, life: 20000 });
            return
        } else {
            this.setState((prevState) => {
                return {
                    selectedExamQuestions: finalQuestions,
                    exam: examT
                };
            });

            this.toast.show({ severity: 'info', summary: 'Status', detail: '"Questions which are satisfied for the exam criteria" are added.', life: 10000 });
        }







    }

    getQuestions = () => {
        let qIds = this.state.questionIds;
        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/question/fetchquestionbyidarray`
        this.service.post(url, { questionIds: qIds, questionTarget: this.state.exam.questionTarget }, true).then((data) => {



            if (data && data.res && data.res.questions && data.res.questions.length) {
                this.setState({
                    isLoading: false,
                    questionIds: []
                }, () => {
                    this.onFetchQuestions(data.res.questions, qIds)
                });
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'No questions', detail: 'No questions found with the ids.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });


    }



    render() {
        const { exam, formValidations, questions, selectedExamQuestions } = this.state;
        console.log('state', this.state)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalFilter} className="ma-tbl-search p-inputtext-md" onChange={(e) => {
                        this.setState({
                            globalFilter: e.target.value
                        })
                    }} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        {/* <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" /> */}
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        //   console.log('this.props', this.props)
        return (<div className='create-quiz'>
            {this.state.currentStep == 1 && <>

                <div className='ma-p20 paper-s1 '>

                    <div>
                        <div className="p-grid ma-w100p " >
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Name of the Test<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.EXAM_NAME} id="kexpT">
                                    <InputText value={exam.examName}
                                        onChange={(e) => { onTextChange(e.target.value, 'examName', this, adhocFields, exam, formValidations, 'exam', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['examName'].isValid && <p className="p-error">{formValidations.fields['examName'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Test Type<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.TEST_TYPE} id="lexpT">
                                    <Dropdown value={exam.testType}
                                        className='ma-w100p'
                                        options={[{ label: 'Open Test', value: 'open' }, { label: 'Closed Test', value: 'closed' }]}
                                        onChange={(e) => {
                                            onDropDownChange(e.value, 'testType', this, adhocFields, exam, formValidations, 'exam', 'formValidations')
                                            this.updateDateFileds(e.value)
                                        }}
                                        placeholder="Select Test Type" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['testType'].isValid && <p className="p-error">{formValidations.fields['testType'].errorMsg}</p>}
                            </div>
                            {
                                exam.testType == 'closed' && <>
                                    <div className="p-col-12 p-md-3">
                                        <p className='ma-label-s2'>Start date and time<span className='ma-required'>*</span></p>
                                        <InputTextB info={FIELDS_INFO.VALID_FROM} id="lexpeT">
                                            <Calendar
                                                value={exam.fromDateTime ? new Date(exam.fromDateTime) : getServerTime()}
                                                showIcon={true}
                                                placeholder='Start Date'
                                                id='fromDates'
                                                showTime
                                                minDate={getServerTime()}
                                                onChange={(e) => this.onExamStartTImeChange(e.value, 'fromDateTime', this, adhocFields, exam, formValidations, 'exam', 'formValidations')}
                                                className='ma-w100p'
                                            ></Calendar>
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['fromDateTime'].isValid && <p className="p-error">{formValidations.fields['fromDateTime'].errorMsg}</p>}
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <p className='ma-label-s2'>End date and time<span className='ma-required'>*</span></p>
                                        <InputTextB info={FIELDS_INFO.VALID_TO} id="leaeT">
                                            <Calendar value={exam.toDateTime ? new Date(exam.toDateTime) : getServerTime()}
                                                showIcon={true}
                                                placeholder='End Date'
                                                id='toDateTi'
                                                showTime
                                                minDate={new Date(exam.fromDateTime)}
                                                onChange={(e) => this.onExamEndTimeChange(e.value, 'toDateTime', this, adhocFields, exam, formValidations, 'exam', 'formValidations')}
                                                className='ma-w100p'
                                            ></Calendar>
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['toDateTime'].isValid && <p className="p-error">{formValidations.fields['toDateTime'].errorMsg}</p>}
                                    </div>
                                </>
                            }
                        </div>
                        <div className="p-grid ma-w100p" >
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Template<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.TEMPLATE} id="lea2eT">
                                    <Dropdown value={exam.templateId}
                                        className='ma-w100p'
                                        options={this.state.examTemplates}
                                        optionValue='_id'
                                        optionLabel='name'
                                        disabled={this.props.editExamId}
                                        //onChange={(e) => { onDropDownChange(e.value, 'testType', this, adhocFields, exam, formValidations, 'exam', 'formValidations') }}
                                        onChange={(e) => { this.onTemplateChange(e.value, 'templateId', this, adhocFields, exam, formValidations, 'exam', 'formValidations') }}
                                        placeholder="Select Template" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3  ">
                                <p className='ma-label-s2'>Board Name<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.BOARD_SELECT} id="leeaeT">
                                    <Dropdown value={this.state.boardId}
                                        className='ma-w100p'
                                        //options={this.state.boards}
                                        options={this.props.boards}
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        disabled={this.props.editExamId}
                                        onChange={(e) => this.onChangeBoard(e.value)}
                                        placeholder="Select Board" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['boardId'].isValid && <p className="p-error">{formValidations.fields['boardId'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Class Name<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.CLASS_SELECT} id="leaaeT">
                                    <Dropdown value={this.state.classId}
                                        options={this.state.classes}
                                        optionLabel="className"
                                        optionValue="classId"
                                        className='ma-w100p'
                                        disabled={this.props.editExamId}
                                        onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['classId'].isValid && <p className="p-error">{formValidations.fields['classId'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3 ">
                                <p className='ma-label-s2'>Question Target<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.QUESTION_TYPES} id="ewhk">
                                    <Dropdown value={exam.questionTarget}
                                        className='ma-w100p'
                                        options={QuestionTargets}
                                        optionLabel="value"
                                        optionValue="id"
                                        disabled={this.props.editExamId}
                                        onChange={(e) =>
                                            onTextChange(e.value, 'questionTarget', this, adhocFields, exam, formValidations, 'exam', 'formValidations')
                                        }
                                        placeholder="Question Target" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['questionTarget'].isValid && <p className="p-error">{formValidations.fields['questionTarget'].errorMsg}</p>}
                            </div>

                        </div>
                        <div className="ma-ml10" >
                            {exam.subjects && exam.subjects.length ? <>
                                {exam.subjects.map((s, index) => {
                                    return <div className='ma-mt20'>
                                        <div className="p-grid ma-w100p" >
                                            <div className="subject-l">
                                                Subject {index + 1} :<span className='ma-required'>*</span>
                                            </div>
                                            <div className="">
                                                <InputTextB info={FIELDS_INFO.SUBJECT_SELECT} id="leabT">
                                                    <Dropdown
                                                        disabled={this.props.editExamId}
                                                        value={s.subjectId}
                                                        options={this.state.subjects}
                                                        optionLabel='subjectName'
                                                        optionValue='subjectId'
                                                        className='ma-m-lr10 ma-w200'
                                                        onChange={(e) => this.onChangeSubject(s.id, e.value)}
                                                        placeholder="Select Subject"

                                                    />
                                                </InputTextB>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </> : null}
                            {formValidations && !formValidations.fields['subjects'].isValid && <p className="p-error">{formValidations.fields['subjects'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
            </>}

            {this.state.currentStep == 2 && <>

                <div className='ma-right '>
                    <p className='adhoc-q-select-c'> <span>{selectedExamQuestions.length}</span> out of {exam.totalNoOfQuestions} Questions selected</p>
                    <p className='adhoc-q-select-c'>All subjects total marks: <span >{exam.totalMarks}</span></p>
                </div>
                <div className='ma-clearFix'>

                </div>

                <div className='adhoc-q-t'>

                    <div className="p-grid ma-w100p ma-p20" >
                        <div className="p-col-12 p-md-6">
                            <TabView
                                activeIndex={this.state.activeSubjectIndex}
                                onTabChange={(e) => this.setState({ activeSubjectIndex: e.index })}
                                className="adhoc-subjects-tabs"

                            >
                                {exam.subjects.map((s) => {
                                    return <TabPanel key={s.id} header={s.subjectName}>
                                        {
                                            s.markingScheme.map((schema, ind) => {
                                                return <div key={'sss' + ind} className="adhoc-q-selection"><span className='q-t-label'>{schema.questionType}</span>  <span className='q-t-counts'>{schema.totalSelectedQuestions} / {schema.totalQuestions}</span> <span className='q-t-label q-tl2 ma-ml20'>{schema.questionType} Marks</span>   <span className='q-t-counts'>{parseInt(schema.totalMarkedQuestions) * parseInt(schema.positiveMarking)}</span>
                                                </div>
                                            })
                                        }
                                    </TabPanel>
                                })}
                            </TabView>
                        </div>
                        <div className="p-col-12 p-md-6 ma-right">
                            <div>
                                <p className='ma-label-s2' >Enter question Ids </p>
                                <div>
                                    <Chips
                                        className=''
                                        value={this.state.questionIds} onChange={(e) => {
                                            this.setState({
                                                questionIds: e.value
                                            })
                                        }}
                                        separator=','
                                    ></Chips>
                                </div>
                            </div>
                            <div>
                                <Button label='Add Questions' className='add-questions'
                                    onClick={this.getQuestions}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable scrollHeight="500px"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={this.state.selectedExamQuestions}
                        className="ma-table-d"
                        tableClassName='ma-table-tbl'
                        paginatorLeft={() => {
                            return <>
                                <p className='avail-q'> Questions selected:  <span>{this.state.selectedExamQuestions.length}</span> </p>
                            </>
                        }}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}

                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={header}
                    >
                        <Column filterField='questionId' headerClassName='question-id' field="questionId" header="Question ID" />
                        <Column filterField='question' headerClassName='question' header="Question and solution" body={(rowData) => {
                            return (<>
                                <QuestionInTable question={rowData} />
                            </>)
                        }} />
                        {this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="questionType" header="Question Type" />}
                        {/* {
                            this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="difficulty" header="Difficulty"

                            />}
                        {
                            this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="skillType" header="Skill Type"

                            />}
                        {
                            this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="examType" header="Exam Type"
                                body={(rowData) => {
                                    return (<>
                                        {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                                    </>)
                                }}
                            />}
                        {
                            this.isColumnVisible('source') && <Column headerClassName='difficulty' field="source" header="Source"
                            />}
                        {
                            this.isColumnVisible('status') && <Column headerClassName='difficulty' field="status" header="Status"
                            />} */}
                        <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                            return (<>
                                <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id} ma-mr20`}
                                    onClick={() => {
                                        this.setState({
                                            isShowQuestionPreview: true,
                                            questionToPreview: rowData
                                        })
                                    }}>
                                    <PreviewIcon width={20} height={20} />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />


                                {/* <Authorizer permId={PERMISSIONS.EXAM_EDIT_QUESTION} > */}

                                <span data-pr-tooltip="Remove Question" data-pr-position="bottom" className={`edit${rowData._id} ma-mr20`} onClick={() => { this.removeQuestion(rowData) }}>
                                    <DeleteIcon width={20} height={20} />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} />
                                {/* </Authorizer>
                                <Authorizer permId={PERMISSIONS.EXAM_PREVIEW_QUESTION} > */}

                                {/* </Authorizer> */}
                            </>
                            );
                        }} ></Column>
                        {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                    </DataTable>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>


            </>}


            <Toolbar className="ma-toolbard ma-mt20" right={() => <>
                {
                    this.state.currentStep == 2 && <Button label='Previous' className='ma-m-lr10 p-button-outlined'
                        onClick={() => {
                            this.setState({
                                currentStep: 1
                            })
                        }} />
                }

                <Button label='Proceed' className='ma-m-lr10'
                    onClick={this.onProceed}

                />

            </>}

            ></Toolbar>



            {
                this.state.isShowQuestionPreview && <PreviewQuestion
                    question={this.state.questionToPreview}
                    onHide={() => {
                        this.setState({
                            isShowQuestionPreview: false
                        })
                    }}
                />
            }
            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </div >)
    }
}


const mapsStatesToProps = (state) => {
    return {
        boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
        isLoading: state.boardsData.loading,
    };
};

export default connect(mapsStatesToProps, {
    getBoardsData,
})(withRouter(CreateAdhoc));
