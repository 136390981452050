import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';


const dispatchRequestDeleteContent = data => ({
    type: ActionTypes.REQUEST_DELETE_CONTENT,
    data
})

const dispatchReceivedDeleteContent = res => ({
    type: ActionTypes.RECEIVED_DELETE_CONTENT,
    res
})

const dispatchErrorDeleteContent = err => ({
    type: ActionTypes.ERROR_DELETE_CONTENT,
    err
})

const dispatchClearDeleteContent = () => ({
    type: ActionTypes.CLEAR_DELETE_CONTENT
})

export const clearDeleteContentData = () => dispatchClearDeleteContent();



const data = {
    "boardId": "90b85367-1740-4324-a231-1f2a0ead10ae",
    "classId": "41f1ce5e-ccb8-1fce-e7ad-71c607ae5d9e",
    "subjectId": "d65b780c-ed2f-4cac-a060-9da43f6d99ff",
    "chapterId": "b0dae824-2435-4a9d-9f55-b0cd914f75cd",
    "topicId": "f494c699-edcc-48cb-b9ba-ddd89e2a0006",
    "groupId": "7b896c4b-12fd-5a55-cdc4-f6b60cbbbfb0",
    "contents": [
      {
        "contentFileType": "pdf",
        "contentStorageKey": "cdb868a0-5c29-11e9-865e-5585279de5e4-SamplePDF.pdf",
        "contentId": "d5d23342-4a21-474f-86fa-fc28c01a5ce3",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SamplePDF.pdf",
        "contentName": "SamplePDF.pdf"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "cdb868a0-5c29-11e9-865e-5585279de5e4-SamplePDF.pdf",
        "contentId": "4276cb91-e544-4489-8264-6e8d712bbe46",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SamplePDF.pdf",
        "contentName": "SamplePDF.pdf"
      },
      {
        "contentFileType": "zip",
        "contentStorageKey": "083498b0-5c29-11e9-b91f-877e5fdd6a73-testppt.zip",
        "contentId": "a54e1abe-2bed-4e9d-9707-999c9d21ebcc",
        "contentResourceType": "zip",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "testppt.zip",
        "contentName": "testppt.zip"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "cf383cc0-5c27-11e9-abfb-dd696ba1a5bb-SamplePDFFile_5mb.pdf",
        "contentId": "3b0ce02a-7c1a-44ee-9a0c-ac5eee8ffedd",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SamplePDFFile_5mb.pdf",
        "contentName": "SamplePDFFile_5mb.pdf"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "08ee36a0-5c27-11e9-b91f-877e5fdd6a73-sample Video.mp4",
        "contentId": "a709847e-b452-45ca-b114-d99afece6c64",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "sample Video.mp4",
        "contentName": "sample Video.mp4"
      },
      {
        "contentFileType": "zip",
        "contentStorageKey": "d530eed0-5c25-11e9-b91f-877e5fdd6a73-SampleZIPFile_10mbmb.zip",
        "contentId": "d6833c86-5fda-40f5-925e-76abc5333752",
        "contentResourceType": "zip",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SampleZIPFile_10mbmb.zip",
        "contentName": "SampleZIPFile_10mbmb.zip"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "799eee30-5ab5-11e9-9b9a-d16a7ed26e5e-pdf.pdf",
        "contentId": "f4372743-dd69-b982-f1c2-e78b10bd54cb",
        "contentResourceType": "PDF",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "pdf112.pdf",
        "contentName": "pdf.pdf"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "e0510b70-5abc-11e9-8b50-0b03306cf99d-video1.mp4",
        "contentId": "4b4cc6af-9c56-3fb2-6202-300fbedfc8ff",
        "contentResourceType": "Video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "video1.mp4",
        "contentName": "video1.mp4"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "cdb868a0-5c29-11e9-865e-5585279de5e4-SamplePDF.pdf",
        "contentId": "d5d23342-4a21-474f-86fa-fc28c01a5ce3",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SamplePDF.pdf",
        "contentName": "SamplePDF.pdf"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "cdb868a0-5c29-11e9-865e-5585279de5e4-SamplePDF.pdf",
        "contentId": "4276cb91-e544-4489-8264-6e8d712bbe46",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SamplePDF.pdf",
        "contentName": "SamplePDF.pdf"
      },
      {
        "contentFileType": "zip",
        "contentStorageKey": "083498b0-5c29-11e9-b91f-877e5fdd6a73-testppt.zip",
        "contentId": "a54e1abe-2bed-4e9d-9707-999c9d21ebcc",
        "contentResourceType": "zip",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "testppt.zip",
        "contentName": "testppt.zip"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "cf383cc0-5c27-11e9-abfb-dd696ba1a5bb-SamplePDFFile_5mb.pdf",
        "contentId": "3b0ce02a-7c1a-44ee-9a0c-ac5eee8ffedd",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SamplePDFFile_5mb.pdf",
        "contentName": "SamplePDFFile_5mb.pdf"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "08ee36a0-5c27-11e9-b91f-877e5fdd6a73-sample Video.mp4",
        "contentId": "a709847e-b452-45ca-b114-d99afece6c64",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "sample Video.mp4",
        "contentName": "sample Video.mp4"
      },
      {
        "contentFileType": "zip",
        "contentStorageKey": "d530eed0-5c25-11e9-b91f-877e5fdd6a73-SampleZIPFile_10mbmb.zip",
        "contentId": "d6833c86-5fda-40f5-925e-76abc5333752",
        "contentResourceType": "zip",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "SampleZIPFile_10mbmb.zip",
        "contentName": "SampleZIPFile_10mbmb.zip"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "799eee30-5ab5-11e9-9b9a-d16a7ed26e5e-pdf.pdf",
        "contentId": "f4372743-dd69-b982-f1c2-e78b10bd54cb",
        "contentResourceType": "PDF",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "pdf112.pdf",
        "contentName": "pdf.pdf"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "e0510b70-5abc-11e9-8b50-0b03306cf99d-video1.mp4",
        "contentId": "4b4cc6af-9c56-3fb2-6202-300fbedfc8ff",
        "contentResourceType": "Video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "video1.mp4",
        "contentName": "video1.mp4"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "e6ab1ec0-5f99-11e9-9faf-797e68a0a37c-04-10-2018_61753.pdf",
        "contentId": "c57275c9-e385-9b78-5887-54e65ad3e532",
        "contentResourceType": "PDF",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "04-10-2018_61753.pdf",
        "contentName": "04-10-2018_61753.pdf"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "059d2da0-5f9a-11e9-9faf-797e68a0a37c-04-10-2018_61753.pdf",
        "contentId": "7e2c6fd0-9325-d4b6-0dca-56eca31ce634",
        "contentResourceType": "PDF",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "04-10-2018_61753.pdf",
        "contentName": "04-10-2018_61753.pdf"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "be087b60-5f9a-11e9-9faf-797e68a0a37c-samplevideo_1280x720_1mb.mp4",
        "contentId": "398ce18a-4232-77ac-0385-e7fa200e19e9",
        "contentResourceType": "Video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "samplevideo_1280x720_1mb.mp4",
        "contentName": "samplevideo_1280x720_1mb.mp4"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "fa67cde0-5f9a-11e9-9faf-797e68a0a37c-samplevideo_1280x720_1mb.mp4",
        "contentId": "fc3c5229-7b41-5d7d-703a-1de15e1b6990",
        "contentResourceType": "Video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "samplevideo_1280x720_1mb.mp4",
        "contentName": "samplevideo_1280x720_1mb.mp4"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "e71678d0-5f9b-11e9-9faf-797e68a0a37c-f2.mp4",
        "contentId": "2a95a92c-25cf-82f8-eb6f-bbf8687dc674",
        "contentResourceType": "Video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "f2.mp4",
        "contentName": "f2.mp4"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "77c45300-6061-11e9-9e75-75bea22c932a-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "77fd8b70-6061-11e9-9e75-75bea22c932a",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "08850e70-6062-11e9-9e75-75bea22c932a-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "08bb60b0-6062-11e9-9e75-75bea22c932a",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "f2ad8a10-606a-11e9-8fbd-4521929fe763-bie-eng-11-bot-the_living_world-1.living_world.pdf",
        "contentId": "16599e90-606b-11e9-8fbd-4521929fe763",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-1.living_world.pdf",
        "contentName": "Introduction11"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "754d0770-60cf-11e9-8bc6-910cfa0a16da-bie-eng-11-bot-the_living_world-1.living_world.pdf",
        "contentId": "9ead6010-60cf-11e9-8bc6-910cfa0a16da",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-1.living_world.pdf",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "7547b040-60cf-11e9-836b-3d056f861bef-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "25431c50-60d0-11e9-836b-3d056f861bef",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "25596370-60d0-11e9-836b-3d056f861bef-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "69d9db60-60d0-11e9-836b-3d056f861bef",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "62bc1800-60d7-11e9-a719-69c73f471b87-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "62f57780-60d7-11e9-a719-69c73f471b87",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "priority": "6",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "a5fa1950-60e1-11e9-b39c-e353eda9e955-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "fb7428c0-60e2-11e9-b39c-e353eda9e955",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "b91526b0-60e1-11e9-85fa-bf19d5b57421-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "fb7c6620-60e2-11e9-85fa-bf19d5b57421",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "d1da97b0-60e2-11e9-b421-19780b281a27-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "05b76ae0-60e3-11e9-b421-19780b281a27",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "fb8e6780-60e2-11e9-b39c-e353eda9e955-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "59e47d60-60e3-11e9-b39c-e353eda9e955",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "fb8e6780-60e2-11e9-b39c-e353eda9e955-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "59e47d60-60e3-11e9-b39c-e353eda9e955",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "2de89250-60e3-11e9-beab-cbe96639d5a4-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "f8324ec0-60e3-11e9-beab-cbe96639d5a4",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "02c67000-60e4-11e9-beab-cbe96639d5a4-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "51d34100-60e4-11e9-beab-cbe96639d5a4",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "pdf",
        "contentStorageKey": "7da33d40-60e8-11e9-85fa-bf19d5b57421-bie-eng-11-bot-the_living_world-1.living_world.pdf",
        "contentId": "10b42a40-60e9-11e9-85fa-bf19d5b57421",
        "contentResourceType": "pdf",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-1.living_world.pdf",
        "contentName": "Introduction11"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "59f85380-60e3-11e9-85fa-bf19d5b57421-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "10b3b510-60e9-11e9-85fa-bf19d5b57421",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "1f35eb50-60e7-11e9-85fa-bf19d5b57421-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "10b40330-60e9-11e9-85fa-bf19d5b57421",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "3793b550-60f2-11e9-b334-09e56521104d-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "7fd987c0-60f4-11e9-b334-09e56521104d",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "34066880-60f5-11e9-87f4-75426d33b56d-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "d92e8770-60f5-11e9-87f4-75426d33b56d",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "3f5a9380-60f7-11e9-9e35-21c409a22918-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "78bb4c00-60f7-11e9-9e35-21c409a22918",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "34066880-60f5-11e9-87f4-75426d33b56d-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "d92e8770-60f5-11e9-87f4-75426d33b56d",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "cbdec8a0-60f5-11e9-b896-03a243715422-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "09c0edc0-60fa-11e9-b896-03a243715422",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "eaf56490-60f7-11e9-87f4-75426d33b56d-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "d32d4280-60fa-11e9-87f4-75426d33b56d",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "678606e0-60fd-11e9-87f4-75426d33b56d-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "aa437210-60fd-11e9-87f4-75426d33b56d",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "48eec890-6105-11e9-a1ad-bb3de5658f68-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "ad9f4da0-6105-11e9-a1ad-bb3de5658f68",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "priority": 1,
        "contentName": "fdsfd"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "f99c5880-6103-11e9-9a76-a9485ee67f4e-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "ec926a60-6105-11e9-9a76-a9485ee67f4e",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentName": "Introduction545"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "ea35b5b0-6105-11e9-a1ad-bb3de5658f68-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "5cf79be0-6106-11e9-a1ad-bb3de5658f68",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "priority": "133",
        "contentName": "test topic"
      },
      {
        "contentFileType": "mp4",
        "contentStorageKey": "c1a493f0-6105-11e9-a1ad-bb3de5658f68-bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "contentId": "5cf726b0-6106-11e9-a1ad-bb3de5658f68",
        "contentResourceType": "video",
        "contentStorageBucketName": "meluhacontentrepository",
        "contentFileName": "bie-eng-11-bot-the_living_world-03_introduction.mp4",
        "priority": "6",
        "contentName": "Introduction"
      }
    ],
    "filename": "71264680-60e2-11e9-8155-7fed7e59babb-bie-eng-11-bot-the_living_world-03_introduction.mp4"
  }


  export const deleteContent = (contentInfo) => dispatch => {
    dispatch(dispatchRequestDeleteContent(contentInfo)); 
    
    let headers = {};
    const etag = localStorage.getItem('etag'); 
    if(etag) {
        headers = {
            "If-Match": etag,
            "Content-Type": "application/json"
        }
    }

    axios({
        method: 'delete',
        url: `${baseUrlAdmin}/content`,
        data: contentInfo,
        headers,
    }).then((res) => {
        dispatch(dispatchReceivedDeleteContent(res.data));
    }).catch(e => {
        dispatch(dispatchErrorDeleteContent(e));
    });
}
  