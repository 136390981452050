import React, { Component } from 'react'
import { I18Next } from '../../../internationalization';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';
import Authentication from './../../session';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const BasicLazyParams = {
    first: 0,
    rows: 5,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


class UsersStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD: false,
            userStats: {
                "activeStudents": 105389,
                "activeTeachers": 0,
                "InActiveStudents": 0,
                "InActiveTeachers": 28745
            },
            defaultLanguage: 'en',
            dialogShow: false,
            dialogHeader: "",
            arrToDataTable: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            userStats1:{}
        };


        this.service = new Service();



    }
    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/admin/user-stats-dashboard1`;
        this.service.get(url, true).then(res => {

            if (res && res.status) {
                this.setState({
                    userStats1: res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {
         this.getReport();
    }


    onClickCard = (n, h) => {

        let url = '';
        if (h == 'Total Users') {
            url = `${baseUrlAdmin}/admin/user-stats-dashboard/users`;
        }

        if (h == 'Total Teachers') {
            url = `${baseUrlAdmin}/admin/user-stats-dashboard/teachers`;
        }

        if (h == 'Inactive Students') {
            url = `${baseUrlAdmin}/admin/user-stats-dashboard/InActive/users`;
        }

        if (h == 'Inactive Teachers') {
            url = `${baseUrlAdmin}/admin/user-stats-dashboard/InActive/teachers`;
        }
        this.setState({
            inlineLoadingD: true,
            dialogShow: true,
            dialogHeader: h,
        })
        this.service.get(url, true).then(res => {
            if (res && res.status) {
                this.setState({
                    arrToDataTable: res.res.data,
                    inlineLoadingD: false
                })

            } else {
                this.setState({
                    inlineLoadingD: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoadingD: false

            });
        })
    }
    onPage = (event) => {
        this.setState({ lazyParams: event });
    }

    render() {
        const { defaultLanguage } = this.props;
        const langType = defaultLanguage
        const { userStats, dialogHeader,arrToDataTable,userStats1 } = this.state;
        console.log(userStats1,"arrToDataTablearrToDataTablearrToDataTable")


        return (<div className='ma-relative'>
            {userStats && <>
                <div className='p-grid ma--white ' >
                    <div className='p-grid p-col-6  p-4'style={{marginLeft:'7px'}}>
                        <div className='p-col-12 p-md-6 ma-center-a ' >
                            <div className='dashboard-card dc-1 ma-pointer' 
                            // onClick={() => this.onClickCard(userStats.activeStudents, "Total Users")}
                            >
                                <p className="card-title"><img src="/new-assets/students.png" style={{ height: '15px' }} /> {"REGION"}</p>
                                <p className="card-value">{userStats1?.level1Count}</p>
                            </div>
                        </div>
                        <div className='p-col-12 p-md-6 ma-center-a'>
                            <div className='dashboard-card dc-3 ma-pointer'
                            // onClick={() => this.onClickCard(userStats.activeStudents, 'Inactive Students')}
                            >
                                <p className="card-title"><img src="/new-assets/in-active.png" style={{ height: '15px' }} />{"AREA"} </p>
                                <p className="card-value">{userStats1?.level2Count}</p>
                            </div>
                        </div>
                    </div>
                    <div className='p-grid p-col-6'>
                        <div className='p-col-12 p-md-6 ma-center-a'>
                            <div className='dashboard-card dc-2 ma-pointer'
                            //  onClick={() => this.onClickCard(userStats.activeStudents, "Total Teachers")}
                            >
                                <p className="card-title"><img src="/new-assets/teacher.png" style={{ height: '15px' }} />{"ZONE"} </p>
                                <p className="card-value">{userStats1?.level3Count}</p>
                            </div>
                        </div>

                        <div className='p-col-12 p-md-6 ma-center-a'>
                            <div className='dashboard-card dc-4 ma-pointer'
                            // onClick={() => this.onClickCard(userStats.activeStudents, "Inactive Teachers")}
                            >
                                <p className="card-title"><img src="/new-assets/Inactive.png" style={{ height: '15px' }} />{"TOTAL USERS"}  </p>
                                <p className="card-value">{userStats1?.count}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                header={<h4>{this.state.dialogHeader}</h4>}
                draggable={false}
                closeOnEscape={true}
                blockScroll={true}
                dismissableMask={false}
                closable={true}
                visible={this.state.dialogShow} style={{ maxWidth: '60vw', minWidth: '60vw' }}
                onHide={() => this.setState({ dialogShow: false, arrToDataTable: [] })}
            >
                <div className='card datatable-crud-demo'>
                    <DataTable stripedRows className='asdf'
                        ref={(el) => this.dt = el}
                        value={this.state.arrToDataTable}
                        dataKey="id"
                        paginator
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                        globalFilter={this.state.globalFilter}
                        header={''}
                    >
                        {this.state.inlineLoadingD && <InLoadingComponent />}
                        <Column header='User Name' field='username' />
                        {(dialogHeader == 'Total Users' || dialogHeader == 'Inactive Students') && <Column header='Given Name' field='given_name' />}
                        {(dialogHeader == 'Total Teachers' || dialogHeader == 'Inactive Teachers') && <Column header='Given Name' field='name' />}

                        <Column header='Email ID' field='email' />
                        {(dialogHeader == 'Total Teachers' || dialogHeader == 'Inactive Teachers') && <Column header='Phone Number' field='phone_number' />}
                        {(dialogHeader == 'Total Teachers' || dialogHeader == 'Inactive Teachers') && <Column header='User Type' field='userType' />}
                        {(dialogHeader == 'Total Users' || dialogHeader == 'Inactive Students') && <Column header='Board' field='board' />}
                        {(dialogHeader == 'Total Users' || dialogHeader == 'Inactive Students') && <Column header='Class' field='grade' />}
                    </DataTable>
                </div>
                {
                    this.state.inlineLoadingD && <InLoadingComponent />
                }
            </Dialog>
            {
                this.state.inlineLoading && <InLoadingComponent />
            }
        </div>
        )
    }
}
const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(Authentication(UsersStats));
